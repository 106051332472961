@import "~@/assets/scss/utils/mixins.scss";

.page__overview--loader {
    position: absolute;
    backdrop-filter: blur(1px);
    webkit-backdrop-filter: blur(1px);
    width: 100%;
    top: 0;
    height: 100%;
}
