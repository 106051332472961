// Font Weights
$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-extra-bold: 800;
$font-stack-inter: "Inter", sans-serif;
$font-stack-serif-display: "DMSerifDisplay", sans-serif;
$font-stack-geist-mono: "GeistMono", sans-serif;
$font-stack-geist: "Geist", sans-serif;

.text {
    font-family: $font-stack-geist;
    
    &--heading{
        font-family: $font-stack-serif-display;
        font-weight: 500;
        line-height: 110%;
    }

    &--medium {
        font-weight: $font-medium !important;
    }

    &--semi--bold {
        font-weight: $font-semi-bold !important;
    }

    &--bold {
        font-weight: $font-bold !important;
    }

    &--upper {
        text-transform: uppercase !important;;
    }
    
    &--lower {
        text-transform:lowercase !important;;
    }

    &--capitalize {
        text-transform: capitalize !important;;
    }

    &--underline {
        text-decoration: underline !important;
    }

    &--color-white {
        color: var(--white);
    }

    &--color-gray-100 {
        color: var(--grey-100) !important;
    }

    &--color-gray-200 {
        color: var(--grey-200) !important;
    }

    &--color-gray-300 {
        color: var(--grey-300) !important;
    }

    &--color-gray-400 {
        color: var(--grey-400) !important;
    }

    &--color-gray-500 {
        color: var(--grey-500) !important;
    }

    &--color-gray-600 {
        color: var(--grey-600) !important;
    }

    &--color-gray-700 {
        color: var(--grey-700) !important;
    }

    &--color-gray-800 {
       color: var(--grey-800) !important;
    }

    &--color-light-300 {
        color: var(--grey-300) !important;
    }

    &--color-primary {
        color: var(--brand-500) !important;
    }

    &--color-warning {
        color: var(--error-600) !important;
    }

    &--color-purple {
        color: var(--purple-500) !important;
    }

    &--color-blue {
        color: var(--info-500) !important;
    }

    &--color-normal {
        color: #5E6C84 !important;
    }

    &-label-info {
        color: rgba(108, 108, 132, 1);
    }

    &--normal {
        font-weight: $font-normal !important;
    }

    &-line-through {
        text-decoration: line-through;
    }

    &-right {
        text-align: right;
    }

    &-center {
        text-align: center;
    }

    &-left {
        text-align: left;
    }

    &-justify {
        text-align: justify;
    }

    &--xxs {
        font-size: 10px;
    }

    &--xs {
        font-size: 12px !important;
    }

    &--sm {
        font-size: 0.875rem !important;
    }

    &--base {
        font-size: 1rem !important;
    }

    &--base, &--xs, &--sm, &--lg, &--xl, &--2xl, &--3xl, &--4xl, &--5xl  {
        line-height: 150%;
    }

    &--md {
        font-size: 1.125rem !important; // 18px

        @include media('<xs_600') {
            font-size: 1rem !important;
        }
    }

    &--lg {
        font-size: 1.25rem; // 20px

        @include media('<xs_600') {
            font-size: 1.125rem !important; // 18px
            line-height: 24px;
        }
    }

    &--xl {
        font-size: 1.125rem !important; // 18px

        @include media('<xs_600') {
            font-size: 1rem !important;
        }
    }

    // 20px
    &--2xl {
        font-size: 1.25rem !important; 

        @include media('<xs_600') {
            font-size: 1.125rem !important; // 18px
        }
    }

    // 24px
    &--3xl {
        font-size: 1.5rem !important; 

        @include media('<xs_600') {
            font-size: 1.25rem; // 20px
        }
    }

    // 32px
    &--4xl {
        font-size: 2rem !important;

        @include media('<lg_992') {
            font-size: 1.875rem !important;
        }
    }

    // 40px
    &--5xl {
        font-size: 2.5rem !important;

        @include media('<lg_992') {
            font-size: 1.875rem !important;
        }
    }

    // 48px
    &--6xl {
        font-size: 3rem !important;

        @include media('<lg_992') {
            font-size: 1.875rem !important;
        }
    }

     // 56px
    &--7xl {
         font-size: 3.5rem !important;
        line-height: 110%;

        @include media('<lg_992') {
            font-size: 2.25rem !important;
        }
    }

     // 64px
    &--8xl {
        font-size: 4rem !important;

        @include media('<xs_600') {
            font-size: 3rem !important;
        }

        @include media('>xs_600', '<lg_992') {
            font-size: 3.75rem !important;
        }
    }

    &--disabled {
        cursor: not-allowed;
        color: var(--grey-600);
    }

    &--info {
        color: #1897b1 !important
    }

    &--ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &--highlight--block {
        background: #eee;
        border-radius: 7px;
        padding: 4px 7px;
        font-weight: 500;
    }
}

.fill {
    &-white {
        fill: var(--white);
    }

    &-primary {
        fill: var(--brand-500) !important;
    }

    &-warning {
        fill: var(--error-600) !important;
    }

    &--purple {
        fill: var(--purple-500) !important;
    }

    &--blue {
        fill: var(--info-500) !important;
    }
}
