@import "~@/assets/scss/utils/mixins.scss";

    .alert {
        padding: 15px 10px;
        border-radius: 6px;

        &--info {
            background: #EDF2F7;
        }

        &--title {
            color: #1A202C;
            font-size: 0.875rem;
            font-weight: 600;
        }

        &--subtitle {
            color: #718096;
            font-size: 0.875rem;
        }
    
    }
