@import "~@/assets/scss/utils/mixins.scss";

.rc {
    &__view {
        &__header {
            &__date {
                color: #38393b;
                font-size: 0.875rem;
                margin-left: 5px;
            }

            &__type {
                color: #38393b;
                font-size: 14px;
                text-transform: capitalize;
                margin-top: 5px;
            }

            &__download {
                border-radius: 5px;
                padding: 5px 8px;
                background: #eee;
                cursor: pointer;
            }
        }

        &__row {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            width: 100%;
            justify-content: flex-end;

            &__text {
                font-size: 0.875rem;
                font-weight: 600;
                text-transform: uppercase;
                margin-left: 10px;
            }
        }

        &__total {
            margin-bottom: 25px;
        }
    }
}
