:root {
    --blue: #1e90ff;
    --black: #000000;
    --white: #ffffff;

    --brand-100: #F0FDFA;  /* Lightest teal - backgrounds */
    --brand-200: #CCFBF1;  /* Light teal - hover states */
    --brand-300: #99F6E4;  /* Medium teal - secondary elements */
    --brand-400: #5EEAD4;  /* Bright teal - accents */
    --brand-500: #289CA9;   /* Primary teal - main CTAs */
    --brand-600: #228E9D;  /* Dark teal - hover states */
    --brand-700: #0F766E;  /* Darker teal - active states */
    --brand-800: #115E59;  /* Deepest teal - special emphasis */

    /* Neutral Colors */
    --neutral-100: #F8FAFC;  /* Lightest - backgrounds */
    --neutral-200: #E2E8F0;  /* Light - borders */
    --neutral-300: #CBD5E1;  /* Medium - disabled states */
    --neutral-400: #94A3B8;  /* Dark - secondary text */
    --neutral-500: #64748B;  /* Darker - body text */
    --neutral-600: #475569;  /* Deep - subtitles */
    --neutral-700: #334155;  /* Deeper - subheadings */
    --neutral-800: #1A202C;  /* Deepest - headings */

    /* Gray colors */
    --grey-100: #9b9aa3;
    --grey-200: #697386;
    --grey-300: #4A5568;
    --grey-400: #3F3D56;
    --grey-500: #2D3748;
    --grey-600: #374151;;
    --grey-700: #09090B;
    --grey-800: #0a2540; 

    /* Supporting Accent Colors */
    --success-500: #22C55E;  /* Success messages */
    --error-500: #EF4444;    /* Error states */
    --error-600: #e04060;    /* Error hover states */
    --warning-500: #F59E0B;  /* Warning messages */
    --info-500: #3B82F6;     /* Information messages */
    --purple-500: #5752eb

    --index: 1;
    --card-top-offset: 1em;
    --space-sm: 0.75em;

    // 172B4D
}

*,
*::before,
*::after { 
  position: relative;
  box-sizing: border-box; 
}

::-webkit-scrollbar {
  display: none;
}

@font-face {
    font-family: 'DMSerifDisplay';
    src: url('@/assets/fonts/DM_Serif_Display/DMSerifDisplay-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'GeistMono';
    src: url('@/assets/fonts/Geist_Mono/GeistMono-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Geist';
    src: url('@/assets/fonts/Geist/Geist-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Geist';
    src: url('@/assets/fonts/Geist/Geist-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Geist';
    src: url('@/assets/fonts/Geist/Geist-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('@/assets/fonts/Inter/Inter-Regular.ttf') format('truetype');;
}
  
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('@/assets/fonts/Inter/Inter-Medium.ttf') format('truetype');;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url('@/assets/fonts/Inter/Inter-SemiBold.ttf') format('truetype');;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('@/assets/fonts/Inter/Inter-Bold.ttf') format('truetype');;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    src: url('@/assets/fonts/Inter/Inter-ExtraBold.ttf') format('truetype');;
}

body {
    font-size: 1rem;
    font-weight: $font-normal;
    color: var(--grey-700);
    line-height: 1.5;
    letter-spacing: 0.4px;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif !important;
    line-height: 1.5;
    font-weight: 400;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    counter-reset: my-sec-counter;
    overflow-x: hidden;
}

html {
    height: 100%;
}

html, html a {
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
}

a {
    text-decoration: none !important;

    &:hover {
        // Implementing a queueing system for handling notifications asynchronously.
        // color: #131313;
        // box-shadow: inset 0 -9px 0 0 #fff200;
    }
}

p {
    margin-bottom: 0!important;
}

label {
    display: block;
    margin-bottom: 5px;
}

.img__responsive {
    max-width: 100%;
}

[class^="icon-"], [class*=" icon-"] {
    height: 32px;
    width: 32px;
    display: inline-block;
    fill: currentColor;
    vertical-align:middle;

    &.icon--md {
        height: 25px;
        width: 25px;
    }

    &.icon--sm {
        height: 20px;
        width: 20px;
    }

    &.icon--xs {
        height: 15px;
        width: 15px;
    }
    &.icon--ss {
        height: 18px;
        width: 18px;
    }
}

.logo {
    &--wrap {
        display: flex;
    }

    &--center {
        justify-content: center;
    }
    
    &--left {
        justify-content: flex-start;

        &--1 {
            padding-left: 1rem;
        }

        &--2 {
            padding-left: 2rem;
        }

        &--5 {
            padding-left: 5rem;
        }
    }

    &--right {
        justify-content: flex-end;
    }
}

.main {
    min-height: 100vh;
    margin-left: auto;
    padding: 80px 30px;
    position: relative;
    transition: width 0.3s ease-in-out;
}

.active {
    display: block;
}

.home {
    &__wrap {
        &--item {
            .header {
                align-items: center;
                display: flex;
                height: 32px;
                justify-content: flex-start;
                margin-bottom: 8px;
                padding: 30px 50px;
                justify-content: space-between;
                border-bottom: 1px solid #e8ecee;
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 10px;

                &__item--wrap {
                    margin: auto;
                    // max-width: 1200px;
                    padding-left: 100px;
                    padding-right: 100px;
                }

                .icon--wrap {
                    flex: 0 0 auto;
                    margin-left: -8px;
                    position: relative;
                    text-align: center;
                    top: 1px;
                    width: 32px;
                }

                .header--text {
                    color: #151b26;
                    font-weight: 500;
                    letter-spacing: 0.04em;
                    line-height: 16px;
                    margin-top: 16px;
                    flex: 1 1 auto;
                    margin: 0;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }

    &--content {
        margin: auto;
        padding-left: 100px;
        padding-right: 100px;

        &.hide {
            height: 0px;
            transition: transform 0.3s ease-out;
            transition: opacity .15s ease-in-out;
        }

        &__wrap {
            margin-top: 1.5rem;
            border-radius: 4px;
            height: 100%;
            overflow: hidden;
            position: relative;
        }
    }
}

.list{
    &--count {
        display: inline;

        p {
            font-size: 16px;
            font-weight: 600;
            color: #1a1f36;
        }
    }
}

.error {
    &__wrapper {
        max-width: 77.7777777778%;
        margin: auto;
        align-items: center;
        display: flex;
        flex-direction: column;
        -webkit-margin-after: var(--space-64);
        margin-block-end: var(--space-64);
        text-align: center;
        padding: 3rem 1rem;
    }

    &__msg {
        max-width: 500px;
        margin-top: 2rem;
    }
}

.card {
    border-width: 1px !important;
    border-radius: 0.5rem !important;
    overflow: hidden;

    &--box {
        padding: 12px 10px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
    }

    &--shadow {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px 0px;
    }

    &--content {
        &__header {
            padding: 12px 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: inset 0 -1px rgba(235, 238, 241, 1);
        }
    }
}

.spinner-border {
    border-width: 0.2rem !important;
}

.filter {
    &__actions--list {
        display: flex;
        align-items: center;
    }
}

.page__row--header {
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 1800px;
    width: 100%;
    justify-content: space-between;
    margin-top: calc(-1 * 0);
    margin-right: calc(-.5 * 1rem);
    margin-left: calc(-.5 * 1rem);
    flex-wrap: wrap;
    padding-bottom: 1rem; 
    padding-top: 0.8rem;
}

.cs {
    &__wrap {
        padding: 70px;
        margin-top: 40px;
        border: 2px dotted #b4a9c8;
        text-align: center;
    }

    &__heading {
        font-size: 1.75rem;
        color: #42526e;
        margin-bottom: 0;
    }

    &__text {
        font-size: 1.25rem;
        color: #42526e;
        opacity: 70%;
    }
}

.pagination-wrap {
    right: 0;
    position: sticky;
    bottom: 0;
    top: 100%;
    position: -webkit-sticky
}

.z_tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}
  
.z_tooltip .z_tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #e9ebf3;
    color: #212;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
    padding: 5px;
}

.z_tooltip .z_tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #e9ebf3 transparent transparent transparent;
}
  
.z_tooltip:hover .z_tooltiptext {
    visibility: visible;
    opacity: 1;
}
  
.z_tooltip.show .z_tooltiptext {
    visibility: visible;
    opacity: 1;
}
  
.home--content__wrap {
    &.list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    &.tiles {
        display: flex;
        flex-direction: revert;
        justify-content: unset;
        align-items: flex-start;
        flex-grow: 1;
    }
}

.ql-toolbar.ql-snow, .ql-container.ql-snow {
    border: none !important;
}

.ql-container.ql-snow {
    max-height: 100px;
    overflow: scroll;
}

.toast-class {
    border-radius: 20px !important;
    font-size: 14px;

    a {
        color: #1a1f36 !important;
        text-decoration: underline !important;
        font-size: 12px !important;
        cursor: pointer !important;
    }
}

.Toastify__toast-theme--colored.Toastify__toast--success {
    color: #40be5e !important;
    background: #fff !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
    color: #e8565a !important;
    background: #fff !important;
}

.z_offcanvas {
    z-index: 10000 !important;
}

.alert-primary {
    color: #181c21 !important;
    background-color: #e9ecf0 !important;
    border-color: #e9ecf0 !important;
}

.empty-page {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}

.count__list {
    color: #697386;
    font-weight: 500;
    margin-left: 4px;

    &__sm {
        font-size: 14px;
    }

    &__md {
        font-size: 1rem;
    }
}

.box-icon {
    width: 5px;
    height: 5px;
    margin-right: 4px;

    &.low, &.flat {
        background: #1e90ff;
    }

    &.medium {
        background: #66BB6A;
    }

    &.high, &.percentage {
        background: #0F4F57;
    }

    &.highest {
        background: #e04060;
    }
}