@import "~@/assets/scss/utils/mixins.scss";


a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}

a:hover {
  color: #535bf2;
}

.file--upload {
    &--wrap {
      font-size: 4vmin;
      display: grid;
      grid-template-rows: 1fr 2em 1em;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 1em;
      min-width: 16em;
      cursor: pointer;
  }

  &--inner {
    border: 1px dashed #4299E1;
    background: #EDF2F7;
    border-radius: 5px;
    padding: 40px 10px; 
    cursor: pointer;
  }

  &--header {
    background: #F6F8FA;
    border-radius: 5px;
    padding: 10px;
    margin-top: 30px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    font-size: 12px;
    justify-content: space-between;

    p {
      color: #2D3748
    }
  }

  &--custom {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}

.modal--header span::after {
  font-size: 10px;
  position: absolute;
  top: 10rem;
  left: 1rem;
  font-family: monospace;
  content: attr(data-state);
  opacity: 0.2;
}

.file--upload--wrap:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #E5E9EE;
  border-radius: 1em;
  transition: transform .3s cubic-bezier(.5, 0, .5, 1);;
  box-shadow: 0 1em 2em rgba(black, 0.1);
  border: 2px dashed #d3d1d1;
}

.file--upload--wrap [data-hidden] {
  transition: all 0.4s cubic-bezier(.5, 0, .5, 1), visibility 0s linear 0.4s;
}
  
.file--upload--wrap [data-hidden="true"] { 
  visibility: hidden;
  opacity: 0;
}
  
.file--upload--wrap [data-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.message {
  overflow: hidden;
  text-align: center;
  font-size: 18px;
}

.message .blue {
  font-weight: 600;
  color: #0570FF;
}

.message strong { 
  display: block; 
  width: 100%; 
  text-align: center;
}
  
.message [data-hidden="true"] { 
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(100%);
}

.file--uploaded--wrap {
  width: 100%;
  margin-top: 1.2rem;
}

.modal--overlay {
  position: fixed; 
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%; 
  height: 100%; 
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  animation-timing-function: cubic-bezier(.4,0,.2,1);
  background-color: rgba(0,0,0,.5);
  display: flex;
  isolation: isolate;
  justify-content: center;
  overflow: hidden;
  transition: background-color .5s;
  z-index: 1000;
}

.modal--header {
  display: flex;
  position: relative;
  margin-bottom: 0.75rem;
  justify-content: flex-end;
}

.modal--body {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px;
  width: 869px;

  &--sm {
    width: 500px !important;

        @media screen and (max-width: 450px) {
            width: 300px;
        }
    }

    &--md {
        width: 650px !important;

        @media screen and (max-width: 800px) {
            width: 500px;
        }
    }
}

.modal--content {
  background-color: #fefefe;
  margin: 5rem auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  border-radius: 10px;
  /* min-height: 407px; */
}

.modal--close {
  color: #aaaaaa;
  font-size: 28px;
  outline: none;
  text-align: center;
  border: none;
  background: transparent;
  cursor: pointer;
  font-weight: 600;
}

.modal--close:hover, .modal--close:focus {
  color: #000;
}
