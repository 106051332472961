@import "variables";
@import "responsive";

// %cover {
//     height: 100%;
//     left: 0;
//     position: absolute;
//     top: 0;
//     width: 100%;
// }

@mixin flex {
  display: flex;
}

@mixin center {
    align-items: center;
    justify-content: center;
}

// %alignCenter {
//     align-items: center;
//     display: flex;
// }

@mixin alignCenter {
  align-items: center;
  display: flex;
}

// %page {
//     @extend %cover;
  
//     opacity: 0;
//     visibility: hidden;
//     z-index: z('content');
//   }
  
//   %page--active {
//     opacity: 1;
//     visibility: visible;
//   }
  
//   %page__wrapper {
//     color: inherit;
//   }

// @mixin ratio ($height, $width) {
//     font-size: 0;
//     overflow: hidden;
//     position: relative;

//     &::after {
//         content: "";
//         display: inline-block;
//         padding-top: $width / $height * 100%;
//         width: 100%                     ;
//     }
// }