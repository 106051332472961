@import "~@/assets/scss/utils/mixins.scss";

    .input-search-select {
        position: relative;
    }

    .search  {
        &__list{
            height: 200px;
            scroll-behavior: smooth;
            overflow-y: scroll;

            &--sm {
                height: auto !important;
            }
        }
    }

    .input__dropdown {
        width: 100%;
        margin-top: 5px;
        background-color: #ffffff;
        position: absolute;
        z-index: 1;
        box-shadow: 0 6px 12px rgba(0,0,0,.175);
        border: solid 1px #c4cdd5;
        border-radius: 5px;
    }

    .input__dropdown__items {
        padding: 10px 0;
    }

    .input__dropdown__item {
        padding: 7px 0px;
        cursor: pointer;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 5px;

        &:hover {
            background: #f9f9f9;
        }
    }

    .no__result {
        display: flex;
        justify-content: center;
        font-size: 14px;
        flex-direction: column;
        align-items: center;

        p {
            &:nth-child(2) {
                color: tan;
                cursor: pointer;
                font-weight: 500;
            }
        }
    }

    .form-search {
        border: 1px solid #ddd;
        // padding: 0px 5px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
