.revenue {
    &__year__select {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
    }

    &__page {
        margin-top: 0.5rem;
    }

    &__stats {
        &__list {
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;
            width: 100%;

            &__item {
                flex: 1;
                
                &:not(:last-of-type) {
                    margin-right: 12px;
                }
            }
        }
    }

    &__goals {
        &__wrap {
            margin-top: 2rem;
        }

        &__tasks {
            margin-top: 1rem;

            &__item {
                display: flex;
                justify-content: space-between;
                font-size: 0.875rem;
                margin-bottom: 5px;

                label {
                    max-width: 90%;
                    width: 90%;
                    cursor: pointer;
                }
            }
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__desc {
            border: 1px solid #e8e6f3;
            border-radius: 6px;
            padding: 5px 10px;
        }
    }

    &__wrap {
        margin-top: 1rem;
    }

    &__date__header {
        color: #64748B;
        font-size: 0.875rem;
        line-height: 150%;
        margin-bottom: 8px;
        padding: 5px 10px;
        font-weight: 600;
        background-color: #F5F6F7;
        border-radius: 6px;
    }

    &__item {
        background-color: transparent;
        border-radius: 0;
        color: inherit;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
        padding: 10px 0;
        -webkit-text-decoration: none;
        text-decoration: none;
        transition: all .3s ease-in-out;
        border-bottom: 1px solid #E5E5E5;
        align-items: center;

        &__left {
            display: flex;
            align-items: center;
        }
    
        &__title {
            font-size: 0.875rem;
            line-height: 140%;
            max-width: 400px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #172b4d;
        }

        &__ref {
            color: #64748B;
            font-weight: 500;
            text-transform: uppercase;
            font-size: 0.75rem;
        }

        &__icon {
            background-color: transparent;
            border-radius: 5px;
            background: #F5F6F7;
            padding: 5px;
            transition: all .3s ease-in-out;
            margin-right: 15px;
        }

        &__amount {
            color: #55c180;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 140%;
            text-transform: uppercase;
        }
    }

    &__info {
        &__item {
            width: 100%;
            
            &:not(:last-of-type) {
                margin-bottom: 0.875rem;
            }

            p {
                font-size: 0.875rem;
            }

            .title {
                color: #64748B;
                font-weight: 500;
            }

            .subtitle {
                margin-top: 5px;
            }
        }
    }
}

.rc {
    &__view {
        display: flex;
        flex-direction: row;
        margin-top: 1rem;
        flex-wrap: wrap;
        max-width: 100%;
        width: 100%;
        margin-bottom: 1.5rem;

        &__header {
            font-size: 18px;
            color: #323336;
            font-weight: 600;
            font-family: $font-stack-geist;
            margin-top: 20px;
        }

        &__desc {
            font-size: 14px;
            color: #565658;
        }

        &__wrap {
            max-height: 500px;
            overflow: scroll;
        }
    }

    &__form {
        max-height: 500px;
        overflow: scroll;
        padding: 0px 5px;

        &__item {
            &__left {
                flex-basis: calc(50% - 13px);
                margin-right: 0px
            }

            &__right {
                flex-basis: calc(50% - 13px);
                width: auto;
                margin-left: 5px;
                display: flex;
                align-items: center;
            }
        }

        &__social {
            &__inputs {
                max-width: 95%;
                width: 95%;
            }

            &__close {
                max-width: 5%;
                width: 5%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }
    }

    &__item {
        position: relative;
        margin: 8px;
        margin-left: 0px;
        cursor: pointer;
        border-radius: 8px;
        background: white;
        padding: 10px 12px;
        min-width: 300px;
        border-color: #e6e5e1;
        border-color: #ebebeb;
        border: 1px solid #ebebeb;
        max-width: 370px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__wrap {
            max-height: 250px;
            overflow: scroll;
        }

        &__dropdown__toggle {
            border-radius: 50%;
            padding: 2px;
            background: #eee;
        }

        &__dropdown {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            width: 100%;
            position: relative;
            justify-content: space-between;
            
            .left {
                max-width: 80%;
            }

            .right {
                max-width: 15%;
                display: flex;
                align-items: center;
            }
        }

        &__inner {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
        }

        &__text {
            font-size: 0.875rem;
            font-family: $font-stack-geist;
            color: var(--grey-200);
            text-transform: capitalize;

            .title {
                font-weight: 600;
            }

            .sub {
                color: #5E6C84;
            }
        }
    }

    &__preview {
        background: #eee;
        padding: 10px 15px;
        border-radius: .25rem;

        &__text {
            font-size: 0.75rem;
            margin-bottom: 10px !important;
            color: #09090B;
        }
    }
}
