@import "~@/assets/scss/utils/mixins.scss";

.timeline {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;

    &__item {
        display: flex;
        align-items: flex-start;

        &:not(:last-of-type) {
            margin-bottom: 10px;

            &::after {
                content: "";
                position: absolute;
                height: 100%;
                background-color: #ccc;
                left: -10px;
                top: 21px;
                width: 1px;            
            }
        }

        &:first-of-type {
            margin-top: 10px;
        }
    }

    &__date {
        font-weight: bold;
    }

    &__amount {
        color: #333;
    }

    &__marker {
        position: absolute;
        left: -18px; /* Adjust the position of the icon */
        background-color: #f6f8fa;
        border-radius: 50%;
        // border: 2px solid #0091FF;
        // width: 18px;
        // height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__content {
        margin-left: 20px; 

        &__title, &__sub {
            font-size: 12px;
        }

        &__title {
            font-weight: 600;
        }
    }
}
