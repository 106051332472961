@import "~@/assets/scss/utils/mixins.scss";

    .offcanvas-end {
        width: 650px;
    }

    thead {
        border-style: solid !important;
    }

    th {
        padding-left: 0px !important;
    }

    .table tbody td {
        overflow-x: auto !important;
        white-space: normal !important;
        text-overflow: unset !important;
    }

    .preview {
        &__header {
            border-bottom: 1px solid #eee;
            padding-bottom: 15px;
        }

        &__dates {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            border-bottom: 1px solid #eee;
            padding-bottom: 10px;
        }

        &__card {
            padding-top: 1.3rem;
        }

        &__row {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            width: 100%;
            justify-content: flex-end;

            &__text {
                font-size: 0.875rem;
                font-weight: 600;
                text-transform: uppercase;
                margin-left: 10px;
            }
        }

        &__card__item {
            &__title {
                font-weight: 500;
                margin-bottom: 3px !important;
                margin-top: 10px;
            }

            p {
                font-size: 0.875rem !important;    
            }
        }

        &__details {
            display: flex;
            align-items: baseline;

            &__item {
                flex: 1;
            }
        }
    }
