@import "~@/assets/scss/utils/mixins.scss";

    .g_id_signin, #g_id_onload {
        width: 100%;
    }

    .google-auth-btn {
        border-radius: 20px;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 10px;
        justify-content: center;
        background: white;
        border: 1px solid #eee;

        img {
            height: 100%;
            max-height: 100%;
            margin-right: 10px;
        }
    }
