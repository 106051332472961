.invoice {
    &__header {
        border-top: 1px solid #f5f5f5;
        padding-top: 10px;
        margin-top: 1rem !important;
        margin-bottom: 10px;

        .title {
            color: var(--grey-600);
            font-weight: 600;
        }

        .subtitle {
            cursor: pointer;
            font-size: 0.75rem;
            font-weight: 600;
            color: var(--grey-600);
        }
    }

    &__row {
        display: flex;
        width: 100%;

        &__left, &__right {
            max-width: 50%;
            flex: 1;
        }
    }

    &__section--item {
        margin-bottom: 10px;
    }

    &__item  {
        display: block;
        width: 100%;

        &--payment {
            max-width: 60% !important;
        }
    } 

    &__details {
        &--item {
            display: flex;
            justify-content: space-between;
            max-width: 100%;
            width: 100%;

            &__header {
                display: flex;
                align-items: center;
                max-width: 1800px;
                width: 100%;
                justify-content: space-between;
                margin-top: 0;
                flex-wrap: wrap;
                padding-bottom: 10px;
            }

            .invoice__compile--label {
                color: #57584e;
            }
        }

        &--text {
            font-size: 0.875rem;
        }

        &__menu {
            border-radius: 6px !important;
            cursor: pointer;
            background: #f1f1f1 !important;
            padding: 0.25rem 0.5rem;
        }

        &__row {
            display: flex;
            width: 100%;
    
            &__left {
                flex: 2;
                border-right: 1px solid #eee;
                padding: 0 1rem 0 0;
            }

            &__right {
                flex: 1;
                padding: 0 1rem;
            }
        }

        &__header {
            display: flex;
            align-items: center;
            max-width: 1800px;
            width: 100%;
            justify-content: space-between;
            margin-top: 0;
            flex-wrap: wrap;
            padding-bottom: 10px;
            border-bottom: 1px solid #E6E6E6;
            padding-bottom: 1rem;
        }

        &__item {
            display: flex;
            flex-wrap: wrap;
            padding: 1rem 0;

            &:not(:last-of-type) {
                border-bottom: 1px solid #E6E6E6;
            }
        }

        &__group {
            font-size: 0.875rem;
            display: flex;
            align-items: center;
            width: 33.3333%;
            margin-bottom: 25px;
        }

        &__divider {
            border-bottom: 1px solid #E6E6E6;
        }
    }
    
    &__calculate {
        &--row {
            display: flex;
            width: 100%;
            align-items: center;

            &.form__row__right {
                flex-basis: calc(50% - 13px);
                margin-left: 13px;
                width: auto;
            }
        }

        &--wrap {
            width: 100% !important;
            display: flex;

            .form__row__left {
                flex-basis: calc(40% - 13px);
                margin-right: 13px;
            }
        }

        &--label {
            flex-basis: 35%;

            label {
                display: inline-block;
                padding: 6px 0;
                font-size: 14px;
                line-height: 1.5;
                color: inherit;
            }
        }

        &__close {
            position: absolute;
            top: 1px;
            right: -15px;
            z-index: 9000;
            cursor: pointer;
        }
    }

    &__compile {
        &--row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 12px;
        }
        &--label, &--value , &--btn {
            color: #3c4257;
            font-size: 14px;
            font-weight: 500;
        }
        &--value {
            font-weight: normal;
        }
        &--btn {
            color: #5469d4 !important;
        }

        &--memo {
            margin-top: 3rem !important;
            display: block !important;

            &--label {
                font-feature-settings: "pnum";
                font-variant: proportional-nums;
                line-height: 28px;
                font-weight: 700;
                font-size: 16px !important;
                color: #3c4257;
            }
            textarea, .due_date {
                width: 500px;
            }
        }
    }

    &__tax {
        &--item {
            &.last {
                margin-top: 10px;
                margin-bottom: 10px;

                .btn--secondary {
                    margin-right: 10px;
                }
            }
            &.dropdown-menu {
                margin-top: 14px;
            }
        }
    }

    &__client {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .heading {
            font-weight: 500;
            margin-bottom: 10px;
            color: #637381;
            font-size: 14px;
        }

        &--bio {
            width: auto;
            margin-bottom: 12px;

            .title {
                font-weight: 500;
                text-transform: capitalize;
                font-size: 14px;
                color: #09090B;
            }
            .sub-title {
                color: #212b36;
                font-size: 14px;
            }

        }

        &__text {
            margin-bottom: 8px !important;
            font-size: 0.875rem;
            color:  var(--grey-500);

            span {
                color: var(--grey-300);
            }
        }
    }

    &__preview {
        &--wrap {
            position: relative;
            visibility: visible;
            justify-content: center;
            display: flex;
            width: 95%;
            margin-left: auto;
            margin-right: auto;
        }

        &--contents {
            overflow: hidden;
            background-color: #fff;
            border: 1px solid #eee;
        }

        &__top {
            height: 6px;
            background: #0569ff;
            width: 100%;
        }

        &--header {
            h1 {
                font-size: 24px;
                margin-bottom: 0px;
                text-transform: uppercase;

            }
            &__no {
                color: #8b9cb2 !important;
                border-bottom: 1px solid #eee;
                padding: 10px 0;
            }
        }
        &--card {
            width: 100%;
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px; 
            background-color: #fff;
            padding: 1rem;
            height: auto;
          
            .top {
                display: flex;
                justify-content: space-between;

                 *h6 {
                    color: #808080;
                    font-weight: 500;
                    font-size: 0.875rem;
                    margin-bottom: 0.2rem!important;
                }

                *p {
                    color: #212b36;
                    font-size: 0.875rem;
                }
                .left {
                    max-width: 50%;
                }
            }
            
        }
    }

    &__manage__tax {
       min-height: 100px;

       &__list {
            max-height: 120px;
            overflow: scroll;
       }
    }
}