.button {
    display: inline-block;
    line-height: 150%;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 8px !important;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    font-family: inherit;
    outline: none;
    font-weight: 500;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.113791);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    
    &:active {
        outline: none;
        box-shadow: none;
        opacity: 0.7;
    }

    span {
        color: inherit;
        line-height: 150%;
    }

    &--default {
        background: var(--brand-500);
        color: var(--white, #fff);

        svg {
            fill: var(--white, #fff);
        }
        
        &:hover {
            background: var(--brand-600) !important;
            
            svg {
                fill: var(--white)
            }    
        }
    }

    &--warning {
        background-color: var(--error-500);
        color: #f2f2f2;
        
        &:hover {
            background-color: darken(#EF4444, 14%);
        }
    }

    &--secondary {
        background-color: var(--neutral-100);
        border: 2px solid var(--brand-500);
        color: var(--brand-500);

        &:hover {
           background-color: var(--brand-100);
        }
    }

    &--xs {
        font-size: 0.625rem !important;
        padding: .125rem .5rem !important;
    }

    &--sm {
        font-size: 0.75rem !important;
        padding: .25rem .5rem !important;
    }

    &--md {
        font-size: 0.875rem !important;
        padding-top: .5625rem !important;
        padding-bottom: .5625rem !important;
    }

    &--lg {
        font-size: 16px;
        padding-top: 14px !important;
        padding-bottom: 14px !important;
        // @include media(768px) {
        //     font-size: 18px;
        //     padding: 18px 16px;
        // }
    }

    &--ghost {
        outline: none;
        border: none;
        background: transparent !important;
        box-shadow: none;   
        
        &__primary {
            color: var(--brand-500);

            svg {
                fill: var(--brand-500);
            }

            :active, &:focus, &:visited, &:hover {
                color: var(--brand-600) !important;

                svg {
                    fill: var(--brand-600);
                }
            }
        }

        &__secondary {
            color: var(--neutral-600); // #637579

            svg {
                fill: var(--neutral-600);
            }

            &:active, &:focus, &:visited, &:hover {
                color: var(--neutral-700);

                svg {
                    fill: var(--neutral-700);
                }
            }
        }

        &__warning {
            background-color: transparent;
            color: $warning-color;
            
            &:hover {
                background-color: darken($warning-color, 14%);
            }
        }
    }

    &--outline {
        box-shadow: none !important;

        &__primary {
            border: 1px solid var(--brand-500);
            background: transparent;
            color: var(--brand-500);
    
            svg {
                fill: var(--brand-500);
            }
            
            &:focus, &:visited, &:hover {
                background: var(--brand-600);
                color: var(--white);
    
                svg {
                    fill: var(--white);
                }
            }
        }

        &__secondary {
            background-color: var(--white);
            border: 1px solid var(--neutral-300);
            color: var(--neutral-500);

            svg {
                fill: var(--neutral-500);
            }

            &:active, &:focus, &:visited, &:hover {
                border: 1px solid var(--neutral-400);
                color: var(--neutral-600);

                svg {
                    fill: var(--neutral-600);
                }
            }
        }

        &__warning {
            border: 1px solid var(--error-500);
            color: var(--error-500);

            svg {
                fill: var(--error-500);
            }

            &:active, &:focus, &:visited, &:hover {
                border: 1px solid var(--error-600);
                color: var(--error-600);

                svg {
                    fill: var(--error-600);
                }
            }
        }
    }

    &-close {
        font-size: 14px !important;
    }

    &--filter {
        border: 1px solid #E6E6E6 !important;
        color: #5e6c84 !important;

        &:hover {
            border: 1px solid #aeb4bb !important;
        }
    }

    &--link {
        background: transparent !important;
        color: var(--brand-500) !important;
        
        &:hover {
            color: var(--color-400, #208490) !important;
        }
    }
}

button[disabled] {
    cursor: not-allowed;
    pointer-events: none;
    color: #1010104d !important;
    
    &.button--default, &.button--secondary, &.button--warning, &.button--outline {
        background: var(--neutral-300) !important;
    }

    svg {
        fill: var(--neutral-300);
    }
}

.link {
    &__primary {
        color: var(--brand-500, #289CA9);
        cursor: pointer;
        
        &:hover  {
            color: var(--purple-500)
        }
    }

    &__secondary {
        color: #697386;
        
        &:hover  {
            color: #393d46;
        }
    }
}