@import "~@/assets/scss/utils/mixins.scss";


.success {
    &__img {
        &__wrap {
            position: relative;
        }

        &__stacked {
            position: absolute;
            bottom: 29%;
            right: 34%;
            bottom: 5%;
        }
    }
}
    .success__modal__body {
        // border: 1px dashed #2121;
        padding: 15px 15px 5px !important
        // border-radius: 5px;
    }
