@import "~@/assets/scss/utils/mixins.scss";

.segmented__bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 6px;

    &__item {
        flex: 1;
        height: 6px;
        background-color: #e5e7eb;
        margin-right: 4px;
        border-radius: 4px;
        
        &.active {
            background-color: #fab316;
            background-color: #228E9D;
        }
        
        /* Remove margin from the last segment */
        &:last-child {
            margin-right: 0;
        }
    }
}
