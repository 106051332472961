.profile {
    &--avatar {
        width: 30px;
        height: 30px;
        font-size: 12px;
        font-weight: 600;

        &:hover {
            background-color: #EEEFFB;
            font-weight: 500;
            cursor: pointer;
        }
    }

    &__bio {
        padding: 8px 14px 8px 0;
        display: inline-flex !important;
        border: 1px solid #eee ;

        &:hover {
            background-color: none !important;
            background: none !important;
            font-weight: 500;
            cursor: default !important;
        }

        &--avatar {
            margin-right: 8px;
            padding: 3px;
            font-size: 10px;
        }

        &--btn {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: rgba(23, 43, 77, 1);
            font-size: 14px;
            user-select: none;
            font-weight: 400;
            text-transform: capitalize;
            cursor: pointer;
        }
    }
}

.header {
    &__btn {
        display: flex !important;
        align-items: center;
        border-radius: 3px;
        border: 0;
        box-shadow: none;
        margin: 0 4px 0 0;
        padding: 0;
        white-space: nowrap;
        padding: 3px 12px !important;

        span.text {
            font-size: 12px !important;
        }

        .svg-icon {
            margin-right: 12px;
            
            svg {
                vertical-align: baseline;
            }
        }
    }

    &__dashboard {
        display: flex;
        justify-content: space-between;
        height: 68px;
        background: #fff;
        padding: 5px 1rem;
        box-shadow: 0 4px 13px rgb(0 0 0 / 5%);

        &__right, &__left {
            display: flex;
            align-items: center;

            .activity {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                vertical-align: middle;
                flex-shrink: 0;
                width: 2.5rem;
                height: 2.5rem;
                overflow: visible;
                position: relative;
                transition: color .15s ease-in-out, background-color .15s ease-in-out, box-shadow .15s ease-in-out;
                border-radius: 100%;
                background: #fff;
                border: 1px solid #e7e9e9;
                margin-right: 3px;
            }
        }

        &__left {
            .breadcrumb-item.active {
                :not(:first-of-type) {
                    &::before {
                        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E");
                    }
                }
            }

            .breadcrumb-item span {
                font-size: 0.875rem !important;
                text-transform: capitalize;
            }
            
            .title__wrap {
                padding-left: 5px;
            }

            .breadcrumb__item {
                font-family: $font-stack-geist;

                .title {
                    color: #09090B;
                    font-size: 1.125rem;
                    font-weight: 600;
                    text-transform: capitalize;
                }

                &.sub, &.head {
                    font-weight: 600;
                    text-transform: capitalize;
                    cursor: pointer;
                }    
        
                &.head {
                    font-size: 0.875rem;
                }

                &.head, .only {
                    color: #09090B;
                }
        
                &.last {
                    color: #697386;
                }

                &.only {
                    font-size: 1.25rem !important;
                    font-weight: 700;
                    text-transform: capitalize;
                }
            }
        
        }

        &__right {
            min-width: 15%;
            justify-content: flex-end;

            >div.dropdown {
                margin-left: 10px;
            }
        }

        &__dropdown--item {
            border-top: solid 1px #E0E0E0;  
            font-size: 0.875rem;  
            cursor: pointer;
            padding: 7px 15px !important;

            &:hover {
                a {
                    color: var(--brand-500, #289CA9);
                }

                svg { fill: var(--brand-500, #289CA9); }
            }

            // &:not(:first-of-type) {
            //     margin-top: 8px;
            // }

            &.no-hover {
                cursor: default !important;

                &:hover, &:focus {
                    background-color: transparent !important;
                }
            }
        }
    }

    &__nav {
        &__link {
            font-size: 0.875rem;
            margin-left: 7px;
        }

        &__email {
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 170px;
            margin-left: 0px;
        }
    }
}

.page {
    &__back {
        &--wrap {
            display: flex;
            align-items: center;
            margin-top: 1rem;
        }
        
        &--text {
            font-size: 14px;
            color: #5469d4;
        }
    }
}

.back--wrap {
    span, a, svg {
        font-size: 1rem;
        color: #3a3a3a !important;
    }
}

.breadcrumb-item {
    display: flex;
    align-items: flex-start;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' style='fill: rgba(105, 115, 134, 1);transform: ;msFilter:;'><path d='M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z'></path></svg>") !important;
}