@import "~@/assets/scss/utils/mixins.scss";

.row  {
  &.header {
    justify-content: space-between;
  }

  .row__left, .row__right {
    width: fit-content;
    max-width: fit-content;
  }
}

