@import "~@/assets/scss/utils/mixins.scss";

    .spinner-border {
        color: var(--brand-500);
        
        &.sm {
            width: 1rem !important;
            height: 1rem !important;
            border-width: 1px !important;
        }

        .base_btn {
            color: #64748b !important;
        }
    }
