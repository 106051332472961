.tags {
    &--item {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin: 8px;
        margin-left: 0px;
        cursor: pointer;
        border: 1px solid #cecece;
        border-radius: 20px;
        background: rgb(255, 255, 255);
        padding: 4px 10px;

        &--text {
            font-size: 14px;
            color: #212529;
        }
    }
}

.tag {
    border-radius: 8px;
    padding: 3px 8px;
    display: inline !important;
    vertical-align: middle !important;
    border-color: unset !important;
    border-style: solid !important;
    border-width: inherit !important;
    border: transparent !important;

    &.bold {
        font-weight: 600;
    }

    &.default {
        font-weight: 500;
    }

    &--cornsilk {
        background: rgb(255, 248, 220, 0.8) !important;
        color:  rgb(210, 105, 30)!important;
        fill: rgb(210, 105, 30) !important;

        &__text {
            background:  rgb(210, 105, 30)!important;
        }
    }

    &--purple-2 {
        background: #e7e2ff !important;
        color: #301a56 !important;
        fill: #301a56 !important;

        &__text {
            background:  #301a56!important;
        }
    }

    &--red {
        background: #DA7DA0 !important;
        color: #f2f2f2 !important;
        fill: #f2f2f2 !important;

        &__text {
            background:  #f2f2f2 !important
        }
    }

    &--pink {
        background: rgb(250, 221, 232, 0.3) !important;
        color: rgb(144, 22, 69) !important;
        fill: rgb(144, 22, 69) !important;

        &__text {
            background:  rgb(144, 22, 69)!important;
        }
    }
    
    &--green {
        background: rgb(217, 237, 219, 0.3) !important;
        color: #228d2d !important;
        fill: #228d2d !important;

        &__text {
            background:  #228d2d!important;
        }
    }

    &--blue {
        background: rgb(219, 234, 254, 0.3) !important;
        color: #1e40af !important;
        fill: #1e40af !important;

        &__text {
            background:  #1e40af!important;
        }
    }

    &--yellow {
        background: rgb(250, 236, 179, 0.3) !important;
        color: #e8bd0d !important;
        fill: #e8bd0d !important;

        &__text {
            background:  #e8bd0d!important;
        }
    }

    &--purple {
        background: rgb(243, 232, 255, 0.7) !important;
        color: #6b21a8 !important;
        fill: #6b21a8 !important;

        &__text {
            background:  #6b21a8!important;
        }
    }

    &--grey {
        background: #F2F2FE !important;
        color: #2c3234 !important;
        fill: #2c3234 !important;

        &__text {
            background:  #70878D!important;
        }
    }
}

.multiselect__tag {
    background: #dcdcdc !important;
    color: #2c3234 !important;
}

.multiselect__option--selected.multiselect__option--highlight, .multiselect__option--highlight {
    background: #f3f3f3 !important;
    color: #35495e !important;
}

.status {
    &__map {
        display: flex !important;
        justify-content: center;
        align-items: center;
        width: fit-content;
    }

    &__circle {
        border-radius: 50%;
        width: 5px;
        height: 5px;
        margin-right: 5px;
    }
}