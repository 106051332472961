.form {
    &-control {
        &-bg {
            &-gray {
                background-color: #F7F7F7 !important;;
            }
            &-white {
                background-color: #fff;
            }
        }

        &-border {
            &-none {
                border: none !important;
            }

            &-solid {
                border: 1px solid #ced4da !important;
            }
            &-dotted {
                border: 1px dotted #ced4da !important;
            }
        }
    }

    &__md {
        width: 450px;
        max-width: 450px;
    }

    &-label {
        white-space: normal;
        line-height: 150%;
        display: block;
        max-width: 100%;
        font-family: $font-stack-geist;

        &.menu {
            font-size: 0.875rem;
            font-weight: 500;
            margin-bottom: 3px;
        }
        
        &.sm_mono, &.form {
            color: var(--grey-200);
        }

        &.title, &.menu {
            color: #171717;
        }

        &.form {
            font-size: 0.8125rem;
            font-weight: 400;
        }

        &.title, &.form {
            margin-bottom: 8px;
        }

        &.title {
            font-weight: 600;
            font-size: 1rem;
        }

        &.sm_mono {
            font-size: 0.75rem;
        }
    }

    &__item {
        margin-bottom: 15px;
        padding: 0 3px;

        &--lg {
            width: 100%;
        }

        &--sm {
            width: 50%;
        }

        &--wrap {
            flex-wrap: wrap;
        }

        &--text {
            font-size: 12px;
            color: var(--grey-600);
            margin-top: 0.4rem;
        }

        &__modal {
            padding-bottom: 20px;
        }
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 1.5rem;

        &--btnFirst {
            margin-right: 0.825rem;
        }
    }

    &__info {
        &--warning {
            background-color: #f4f5f7;
            border: 1px solid #dfe1e6;
            padding: 14px 20px;
            border-radius: 3px;

            p {
                color: #172b4d;
            }
        }
    }

    &__errors {
        margin-top: 0.625rem !important;
        font-size: 12px;

        &--icon {
            margin-right: 0.325rem;
        }
    }

    &__input, &__input--none, textarea {
        flex-grow: 1;
        white-space: nowrap;
        transition: color .24s;
        border-radius: 6px !important;
        border: 1px solid var(--gray-300, #E0E0E0);
        background-color: var(--white);
        font-size: 0.875rem;
        font-style: normal;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        position: relative;
        font: inherit;
        min-width: 0;
        display: inline-flex;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        line-height: normal;
        outline: none !important;
        -webkit-tap-highlight-color: transparent;
        font-family: $font-stack-geist;

        &-group {
            position: relative;
            display: flex;
            align-items: stretch;
            width: 100%;

            .left-icon {
                display: flex;
                align-items: center;
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #373f45;
                opacity: 0.7;
                text-align: center;
                white-space: nowrap;
                background-color: inherit;
                border-left: 1px solid #dee2e6;
                border-top: 1px solid #dee2e6;
                border-bottom: 1px solid #dee2e6;
                border-radius: 0.375rem;
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }

            input {
                // Hack to remove native blue color on Chrome
                &:-webkit-autofill { 
                    -webkit-background-clip: text;
                }
                
                &:-webkit-text-fill-color { white: !important };   
                
                &.prefix {
                    border-left: none;
                    margin-left: calc(1px * -1);
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                    position: relative;
                    flex: 1 1 auto;
                    width: 1%;
                    min-width: 0;
                    display: block;
                }
            }

            .input--password {
                top: 28%;
                right: 5px;
                max-width: 24px; 
                max-height: 24px;
            }
        }

        &-error {
            border: 1px solid var(--z-red-100, #E64A19) !important;
            background-color: var(--z-red-300, #FCEFEB) !important;
        }

        &.disabled, &[disabled], &[readonly] {
            border: 1px solid var(--gray-300, #E0E0E0) !important;
            background-color: var(--gray-100, #F5F5F5) !important;
            pointer-events: none;
            cursor: not-allowed;
        }

        &__border__none {
            border: none !important;
            outline: none !important;
            flex-grow: 1;
            white-space: nowrap;
            transition: color .24s;

            &:focus {
                color: #212529;
                border-color: transparent !important;
                outline: 0 !important;
                border: none !important;;
                box-shadow: none !important;;
            }
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;

        .form__item__modal {
            flex: 1;
        }
    }

    &__err-msg {
        color: var(--z-red-100, #E64A19);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        margin-top: 5px;
    }

    &-check-input:checked {
        background-color: #289CA9 !important;
        border-color: #289CA9 !important;
    }
}

textarea {
    background-color: #fafbfc;
    border-radius: 3px;
    box-sizing: border-box;
    min-height: 32px;
    padding: 6px 12px;
    position: relative;
    outline: none;
    border: 1px solid #e0e1e4;
}

.checkbox {
    display: flex;
    align-items: center;

    &__item {
        display: flex;
        align-items: center;
    }

    &--label {
        font-size:0.875rem;
        color: #3c4257;
        display: block;
        line-height: 20px;
        white-space: normal;
        margin-left: 5px;
    }
    
    label {
        font-size: 12px;
        color: #42526e;
        margin-left: 5px
    }
}

.phone-country {
    &-wrap {
        display: flex;
        align-items: center;
        border: 1px solid #ced4da !important;
        border-radius: 6px !important;

        input {
            border: none !important;
            outline: none !important;
        }

        .country-wrap {
            max-width: 50px;
            border-right: 1px solid #ced4da !important;
            margin-right: 1rem;
        }
    }

    &-select {
        border: none !important;
        font-size: 12px !important;
        background-size: 10px 10px !important;
        cursor: pointer;
        height: 100%;
    }
}

.form__textarea__group {
    display: flex;
    flex-direction: column;
    border: 1px solid #ced4da;
    width: 100%;
    position: relative;
    border-radius: 4px;

    textarea {
        border: none !important;
        outline: 0 !important;
        // border-radius: 0 !important;
        box-shadow: none !important;
        padding-top: 10px;
        width: 100%;
        resize: none;
        overflow: hidden;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .form__textarea__wrap {
        // max-height: 100px;
        // overflow: scroll;
    }
}

.form-select-sm, .form-select {
    border-radius: 5px !important;
}