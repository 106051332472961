@import "~@/assets/scss/utils/mixins.scss";

    .dropdown-rates {
        .dropdown--item {
            text-transform: capitalize;
        }
    }

    .dropdown-currency {
        .dropdown--item {
            text-transform: uppercase;
        }
    }
