.calendar {
    &__header {
        color: #4A556A;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0.75rem;
        border-bottom: 1px solid #E6E6E6;
    }

    &__item {
        padding: 0.875rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 1rem;

        .left {
            width: 90%;
            max-width: 90%;
            display: flex;
            align-items: center;
        }

        .right {
            width: 5%;
            max-width: 5%;
        }
        &__text {
            margin-left: 5px;

            .title {
                font-size: 0.875rem;
                font-weight: 500;
            }

            .live {
                font-size: 0.75rem;
                margin-top: 2px;
            }
        }
    }
}