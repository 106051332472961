@import "~@/assets/scss/utils/mixins.scss";

.rank {
    &__group {
        &__text {
            &__title {
                color: #374151;
                font-size: 0.875rem;
                font-weight: 600;
            }

            &__desc {
                color: #667085;
                font-size: 0.875rem;
            }
        }
    }

    &__img {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 1.8rem;

        &__item {
            color: #64748B;
            font-size: 0.875rem;  
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;  
            max-width: 72px;
            position: relative;

            &:not(:last-of-type) {
                &::after {
                    min-width: 64px;
                    border-color: inherit;
                    height: 2px;
                    content: '';
                    position: absolute;
                    left: 100%;
                    bottom: 61%;
                    background: #A3B4D0;
                }
            }
            
            &__symbol {
                width: 72px;
                height: 72px;
                border-radius: 7px;
                border: 1px solid #A3B4D0;
                display: flex;
                align-items: center;
                padding: 10px;
                justify-content: center;

                &.active {
                    border: 1px solid #137B87;
                }
            }

            &__title {
                margin-top: 10px;
                text-align: center
            }

            &.active {
                color: #137B87;
                font-weight: 600;

                .rank__img__item__symbol {
                    border: 2px solid #137B87;
                }

                &::after {
                    background: #137B87;
                }
            }

            &__award {
                position: absolute;
                z-index: 10000;
                top: -19%;
                left: -41%;
            }
        }
    }

    &__desc {
        background: #F7F7F7;
        border-radius: 7px;
        padding: 15px;
        margin-top: 2rem;

        &__text {
            color: #3F3D56;
            margin-bottom: 10px;
            font-size: 0.875rem;
        }
    }

    &__generate {
        margin-top: 2.5rem;

        &__btn {
            margin-top: 1rem;
        }
    }

    &__share {
        &__text {
            .title {
                color: #374151;
                font-size: 1rem;
                font-weight: 600;
            }
            .desc {
                color: #687385;
                font-size: 0.875rem;
                margin-top: 5px
            }
        }

        &__action {
            margin-top: 2rem
        }

        &__icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            flex: 1;

            &__wrap {
                display: flex;
                align-items: center;
                width: 100%;
                margin-top: 2.5rem;
                margin-bottom: 2rem;
                align-items: center;
                justify-content: center
            }

            p {
                color: #687385 !important;
                margin-top: 7px;
            }

            svg {
                stroke: #B3BACA;

                circle {
                    stroke: #B3BACA;
                } 
                path {
                    stroke: #687385;
                    fill: #687385;
                }
            }

            &.selected {
                svg {
                    stroke: #137B87 !important;

                    circle, path {
                        stroke: #137B87 !important;
                    }

                    path {
                        fill: #137B87 !important;
                    }
                }
                p {
                    color: #137B87 !important;
                    font-weight: 600;
                }
            }
        }

        &__link {
            display: flex;
            margin-top: 2rem;
            align-items: center;

            &__text {
                background: #F7F7F7;
                padding: 10px;
                font-size: 0.75rem;
                color: #3F3D56;
                flex: 1;
                border-radius: 5px;
            }
        }
    }
}
