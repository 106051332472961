@import "~@/assets/scss/utils/mixins.scss";

.modal {
    &--overlay {
        position: fixed; 
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%; 
        height: 100%; 
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
        animation-timing-function: cubic-bezier(.4,0,.2,1);
        background-color: rgba(0,0,0,.5);
        display: flex;
        isolation: isolate;
        justify-content: center;
        overflow: hidden;
        transition: background-color .5s;
        z-index: 1000;
    }

    &--header {
        display: flex;
        position: relative;
        justify-content: space-between;
        box-shadow: inset 0 -1px #ebeef1;
        padding: 10px 15px;
    }

    &--content {
        background-color: #fefefe;
        margin: 5rem auto;
        border: 1px solid #888;
        width: 80%;
        border-radius: 10px;
        min-height: 566px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        span::after {
            font-size: 10px;
            position: absolute;
            top: 10rem;
            left: 1rem;
            font-family: monospace;
            content: attr(data-state);
            opacity: 0.2;
        }

        &--inner {
            // padding: 5px 20px 0px;
            min-height: 431px;
        }
    }

    &--body {
        align-items: center;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 32px;
        padding: 20px;
        width: 650px !important;

        @media screen and (max-width: 800px) {
            width: 500px;
        }
    }

    &--close {
        color: #aaaaaa;
        font-size: 28px;
        outline: none;
        text-align: center;
        border: none;
        background: transparent;
        cursor: pointer;
        font-weight: 600;

        &:hover, &:focus {
            color: #000;
        }
    }

    &--footer {
        display: flex; 
        align-items: center; 
        justify-content: flex-end;
        padding: 10px 15px;
        border-top: solid 1px #E0E0E0;  
    }
}

.time__tracking__text {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid red;
    height: 100%;
    width: 100%;
}
