// home
.landing {
    &__main {
        &__bg {
            &__inner {
                padding-left: 2rem;
                padding-right: 2rem;
                position: unset;
            }

            &.noisy {
                background-image: url('@/assets/img/bg_body_img.png');
                background-repeat: no-repeat;
                background-size: cover;
                background-color: transparent;
            }

            &.normal {
                background-color: #F3F1ED;
            }
        }
    }

    &__section {
        position: relative;
        z-index: 1;

        @include media('<lg_992') {
           margin-top: 5rem;
        }

        @include media('>lg_992') {
           margin-top: 8rem;
        }
    }

    &__nav {
        max-height: 75px;
        position: unset;

        &__wrap {
            width: 100%;
            padding: 30px 0px;
            align-items: center;
            display: flex;
            position: unset;

            >a {
                @include media('>lg_992') {
                   flex: 1;
                }
            }

            @include media('<lg_992') {
               padding: 1.5rem 0px;
               justify-content: space-between;
            }

            @include media('>lg_992') {
                margin-left: auto;
                margin-right: auto;
                max-width: 90.90%;
            }
        }

        &__links {
            flex: 2;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include media('<lg_992') {
               display: none !important;
            }

            @include media('<xl_1200') {
               flex: 3;
            }
        }

        &__list {
            display: flex;
            align-items: center;
            margin-bottom: 0 !important;
            background: rgba(30,30,30, 5.88%);
            padding: 12px 22px !important;
            border-radius: 8px;

            &.mobile {
                flex-direction: column;
                justify-content: center;
                border: none;
                background: transparent;

                li {
                    text-align: center;
                    margin-bottom: 1rem;

                    &:not(:last-child) {
                        margin-right: 0rem;
                    }

                    a {
                        font-size: 1rem;
                        color: #374151;
                        font-weight: 500;

                        &:hover {
                            color: var(--brand-500, #0D4248) !important;
                        }
                    }
                }
            }

            &__item {
                &:not(:last-child) {
                    margin-right: 2.4375rem;
                }

                a {
                    font-size: 0.875rem;
                    color: #374151;
                    font-weight: 500;

                    &:hover {
                        color: var(--brand-500, #0D4248) !important;
                    }
                }
            }
        }

        &__cta {
            @include alignCenter;
            justify-content: flex-end;

            button {
                &:first-of-type {
                    margin-right: 5px;
                }
            }

            &.mobile {
                flex-direction: column;
                width: 100%;
            }
        }

        &__icon {
            display: flex;
            flex-direction: column;

            &.transform .one {
                transform: translate(0, 6px) rotate(-46deg)
            }

            &.transform .two { opacity: 0; }

            &.transform .three {
                transform: translate(0, -14px) rotate(46deg)
            }

            .bar {
                width: 24px;
                height: 2px;
                background-color: #333;
                margin: 4px 0;
                transition: 0.4s;
            }
        }

        &__mobile {
            position: unset;
        }

        &__content {
            background: red;
            background: #f3f1ed;
            position: absolute;
            z-index: 1000;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            transition: 0.5s;
            overflow-x: hidden;
            height: 0;
            width: 0;
            padding: 0;
        }
    }

    &__hero {
        @include media('<xs_600', '<lg_992') {
            padding: 30px;
        }

        &__wrap {
            max-width: 100%;
            margin: auto;
            padding: 10px 1.5rem;
            padding: 0px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-top: 7rem;
            display: flex;
            justify-content: space-between;
            max-width: 90.90%;

            @include media('<lg_992') {
                width: 100%;
                max-width: 100% !important;
                display: flex;
                flex-direction: column;
                margin-top: 3rem;
            }

            &__text {
                max-width: 573px;
            }
        }

        &__title {
            margin-bottom: 1.5rem;
            position: relative;
            max-width: 670px;

            // mark {
            //     display: inline-block;
            //     line-height: 0em;
            //     padding: 0;
            //     padding-bottom: 0.5em;
            //     background-color: #fcf8e3;
            //     background-color: #fbf2c4;
            //     color: inherit;
            //     border-radius: 1px;
            // }
        }

        &__img {
            border-radius: 20px;
            border: 1px solid #eee;
            background: #fff;
            padding: 10px;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.113791);
            position: relative;
            animation: bounceIn 2s infinite 2s;

            @include media('<lg_992') {
                max-width: 90.9%;
                width: 100%;
                margin-top: 4rem;
                margin-left: auto;
                margin-right: auto;
            }

            @include media('>lg_992') {
                max-width: 45%;
            }

            &__wrap {
                height: 100%;
                object-fit: cover;

                img {
                    width: 100%;
                    object-fit: cover;
                    height: 100%;
                }
            }

            &__text {
                position: absolute;

                &.top__img {
                    @include media('<lg_992') {
                        right: -10%;
                        top: 13px;
                    }

                    @include media('>lg_992') {
                        left: 72%;
                        top: 0;
                    }
                }

                &.bottom__img {
                    bottom: 0;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    .one {
                        @include media('<sm_600') {
                           left: -17px;
                        }

                        @include media('>sm_600', '<lg_992') {
                            left: -21px;
                        }

                        @include media('>lg_992') {
                            left: -39px;
                        }
                    }

                    .two {
                        @include media('<sm_600') {
                            right: -8%;
                        }

                        @include media('>sm_600', '<lg_992') {
                            right: -4%;
                        }

                        @include media('>lg_992') {
                            right: -10%;
                        }
                    }
                }
            }
        }

        &__text {
            max-width: 500px;
        }
    }
    
    &__features {
        background: #000;

        @include media('<lg_992') {
            padding-top: 4rem;
            padding-bottom: 4rem;
        }

        @include media('>lg_992') {
            padding-top: 8rem;
            padding-bottom: 8rem;
        }

        &__title {
            font-weight: 600;
            margin-bottom: 1rem;
            color: #f2f2f2;

            @include media('<md_768') {
                width: 100%;
            }

            @include media('>lg_992') {
                width: 60%;
            }
        }
    
        &__subtitle {
            color: #f2f2f2;

            @include media('<md_768') {
                width: 100%;
            }

            @include media('>lg_992') {
                width: 40%;
            }
        }

        &__wrap {
            margin-bottom: 2.5rem;
            margin-left: auto;
            margin-right: auto;
                        
            @include media('<lg_992') {
                max-width: 100%;
                padding: 1.8rem 25px 2rem;
            }

            @include media('>lg_992') {
                max-width: 90.9%;
                padding: 1.8rem 25px 5rem;
            }
        }

        &__text {
            width: 100%;
            display: flex;

            @include media('<lg_992') {
                flex-direction: column;
                flex-wrap: nowrap;
            }
        }

        &__cards { 
            &__list {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                width: 100%;
                max-width: 90.9%;
                margin: auto;

                &__item {
                    border-radius: 10px;
                    background: #fff;
                    padding: 30px 20px;
                    margin-bottom: 2.25rem;

                    &:hover {
                        box-shadow: inset 6px 5px 11px 5px rgb(158 35 35 / 20%), 0 1px 5px -1px rgb(149 28 28 / 80%);
                    }

                    @include media('<sm_600') {
                        max-width: 100%;
                        width: 100%;
                    }

                    @include media('>sm_600', '<lg_992') {
                        max-width: 100%;
                        width: 100%;
                    }

                    @include media('>lg_992') {
                        max-width: 400px;
                        width: 400px;
                    }
                }
            }
        }
    }

    &__integrations {
        width: 100%;

        @include media('>lg_992') {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }

        &__text {
            text-align: center;
            color: #070707;
            width: 100%;
            margin: auto;
            max-width: 692px;
        }

        &__cards {
            margin-top: 5rem;
            max-width: 1340px;
            margin-left: auto;
            margin-right: auto;
            max-width: 90.9%;

            &__list {
                display: flex;
                // flex-wrap: wrap;
                width: 100%;
                justify-content: space-evenly;
                height: auto;
                flex-direction: column;

                @include media('>lg_992') {
                    flex-direction: row;
                }

                &__item {
                    position: relative;
                    width: 200px;
                    transition: all 0.2s ease-out 0s, visibility 0s;
                    --transition: all 0.2s ease-out 0s, visibility 0s;
                    background: #fff;
                    padding: 20px;
                    border-radius: 10px;
                    box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2);

                    @include media('<lg_992') {
                        min-width: 100%;
                        max-width: 100%;
                    }

                    @include media('>lg_992') {
                        min-width: 400px;
                        max-width: 400px;
                    }

                    &:hover {
                        box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.5);
                        cursor: default; 
                    }
    
                    .title {
                        color: #070707;
                        font-size: 1.25rem;
                        font-weight: 600;
                        transition: all 0.4s ease-in-out 0s, visibility 0s;
                        --transition: all 0.4s ease-in-out 0s, visibility 0s;   
                    }

                    &:not(:last-of-type) {
                        @include media('<lg_992') {
                            margin-bottom: 1.5rem;
                        }

                        @include media('>lg_992') {
                            margin-right: 1rem;
                        }
                    }
    
                    img {
                        transition: all 0.2s ease-in 0s, visibility 0s;
                        --transition: all 0.2s ease-in 0s, visibility 0s;
                    }
    
                    .desc {
                        color: #070707;
                        font-size: 1rem;
                        transition: all 0.7s ease-in 0s, visibility 0s;
                        --transition: inherit;
                        text-align: left;
                    }
                }
            }
        }
    }

    &__pricing {
        width: 100%;
        background: #000;
        color: #f2f2f2;

        @include media('<lg_992') {
            padding: 5rem 25px;
        }

        @include media('>lg_992') {
            padding: 9rem 25px;
        }

       &__text {
            text-align: center;
            color: #f2f2f2;
            width: 100%;
            margin: auto;
        }

        &__cards {
            margin-top: 3.5rem;
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;

            &__list {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                justify-content: center;
                height: auto;

                &__item {
                    position: relative;
                    width: 200px;
                    transition: all 0.2s ease-out 0s, visibility 0s;
                    --transition: all 0.2s ease-out 0s, visibility 0s;
                    background: #D9EDDB;
                    padding: 20px;
                    border-radius: 10px;
                    box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2);
    
                    .title {
                        color: #070707;
                        font-size: 1.25rem;
                        font-weight: 600;
                        transition: all 0.4s ease-in-out 0s, visibility 0s;
                        --transition: all 0.4s ease-in-out 0s, visibility 0s;   
                    }

                    &:not(:last-of-type) {
                        @include media('<lg_992') {
                            margin-bottom: 1.5rem;
                        }

                        @include media('>lg_992') {
                            margin-right: 2rem;
                        }
                    }
    
                    img {
                        transition: all 0.2s ease-in 0s, visibility 0s;
                        --transition: all 0.2s ease-in 0s, visibility 0s;
                    }
    
                    .desc {
                        color: #070707;
                        font-size: 1rem;
                        transition: all 0.7s ease-in 0s, visibility 0s;
                        --transition: inherit;
                        text-align: left;
                    }

                    .inner {
                        background: #fff;
                        border-radius: 10px;
                        padding: 15px 20px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }

                    .plans__check {
                        border-radius: 50%;
                        width: 18px;
                        height: 18px;
                        border: 1px solid #6c6c6c;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        svg {
                            fill: #6c6c6c;
                        }
                    }

                    @include media('<lg_992') {
                        min-width: 100%;
                    }

                    @include media('>lg_992') {
                        min-width: 400px;
                    }
                }
            }
        }
    }

    &__faqs {
        max-width: 76.3888888889%;
        margin-left: auto;
        margin-right: auto;

        @include media('>lg_992') {
            padding: 0rem 8rem;
        }

        &__wrap {
            margin-top: 3rem;
        }

        &__list {
            &__item {
                button {
                    background-color: transparent;
                    color: #172B4D;
                    width: 100%;
                    border: none;
                    text-align: left;
                    outline: none;
                    transition: 0.3s ease;

                    &:not(:nth-child(6)) {
                        border-bottom: 1px solid #929292;
                    }

                    &:after {
                        content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBzdHlsZT0iZmlsbDogIzkyOTI5Mjt0cmFuc2Zvcm06IDttc0ZpbHRlcjo7Ij48cGF0aCBkPSJNMTkgMTFoLTZWNWgtMnY2SDV2Mmg2djZoMnYtNmg2eiI+PC9wYXRoPjwvc3ZnPg==");
                        color: #929292;
                        float: right;
                        margin-left: 5px;
                        transition: transform 0.3s ease;
                    }

                    &.active:after {
                        transition: transform 0.3s ease;
                        content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBzdHlsZT0iZmlsbDogIzkyOTI5Mjt0cmFuc2Zvcm06IDttc0ZpbHRlcjo7Ij48cGF0aCBkPSJNNSAxMWgxNHYySDV6Ij48L3BhdGg+PC9zdmc+");
                    }

                    @include media('<lg_992') {
                       font-size: 1.25rem;
                       padding: 1.5rem 0;
                    }

                    @include media('>lg_992') {
                        font-size: 1.5rem;
                        padding: 2rem 0;
                    }
                }

                &__panel {
                    color: #172B4D;
                    max-height: 0;
                    overflow: hidden;
                    font-size: 16px;
                    display: none;
                    transition: max-height 0.3s ease-out, padding 0.3s ease;
                    border-radius: 0 0 8px 8px;

                    &.active {
                        display: block !important;

                    }
                }
            }
        }
    }

    &__contact {
        background: #EBE5D5;
        margin-left: 1rem;
        margin-right: 1rem;
        border-radius: 10px;

        &__wrap {
            display: flex;
            width: 100%;
            margin: auto;
            justify-content: space-between;

            @include media('<lg_992') {
                max-width: 100%;
                padding: 3rem 25px;
            }

            @include media('>lg_992') {
                max-width: 90.9%;
                padding: 6rem 25px;
            }
        }

        &__text {
            max-width: 735px;
        }

        &__icon {
            @include media('<lg_992') {
                display: none;
            }

            @include media('>lg_992') {
                display: flex;
            }
        }
    }

    &__footer {
        padding:  3.1875rem 5.75rem;

        @include media('<lg_992') {
            padding: 30px;
        }

        &__intro {
            width: 100%;
            max-width: 100%;
            margin-bottom: 3.9375rem;
            margin-bottom: 1.9375rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include media('<lg_992') {
                margin-bottom: 2.9375rem;
            }

            p {
                color: #374151;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                max-width: 211px;
                margin-top: 1.3125rem;
            }
        }

        &__links {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            border-top: 1px solid #d3d2d2;
            padding-top: 3rem;
        }

        &__list {
            text-transform: uppercase;
            color: #070707;
            font-family: Rounded Mplus 1c Bold;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @include media('<lg_992') {
                width: 50%;

                &:not(:last-of-type) {
                    margin-bottom: 2rem !important;
                }
            }

            li {
                &:first-of-type {
                    margin-top: 1rem;
                }
                
                &:not(:last-of-type) {
                    padding-bottom: 0.8rem;
                }

                a, span {
                    text-transform: capitalize;
                    font-size: 0.875rem;
                    color: #374151;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.25rem;
                }
            }
        }

        &__copyright {
            margin-top: 4.4375rem;

            @include media('<lg_992') {
                margin-top: 2.4375rem;
            }

            p {
                color: #667085;
                font-family: Inter;
                font-size: 12px;
                font-weight: 400;
                line-height: normal;
            }
        }

        .bg__control {
            display: flex;
            align-items: center;
            color: #374151;
            font-size: 12px;
            cursor: pointer;

            svg {
                fill: #374151;
            }

            &:hover {
                color: #289CA9;
                
                svg {
                    fill: #289CA9;
                }
            }
        } 
    }
}

.animated__card {
    /* Create View Timeline */
    view-timeline-name: --revealing-image;

    /* Attach animation, linked to the  View Timeline */
    animation-name: revealCards;
    animation-fill-mode: both;
    animation-timeline: --revealing-image;
    animation-duration: 1ms; /* Firefox requires this to apply the animation */
}

@keyframes revealCards {
	from {
		opacity: 0;
		// clip-path: inset(45% 20% 45% 20%);
        transform: scaleX(0);
	}
	to {
		opacity: 1;
		// clip-path: inset(0% 0% 0% 0%);
        transform: scaleX(1);
	}
}

@keyframes scale {
	to {
		transform:
			scale(calc(1.1 - calc(0.1 * var(--reverse-index))));
	}
}

@keyframes bounceIn {
    0%   {left: 0px;}
    25%  {left: 2px;}
    75%  {left: 5px}
    100% {left: 0px;}
}

.join__waitlist {
    @include center;

    width: 70%;
    margin: 1.75rem auto 0;

    @include media('<lg_992') {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    input {
        border-radius: 20px;
    }

    &__input {
        flex: 2;

        @include media('<lg_992') {
            width: 100%;
        }
    }

    &__button {
        margin-left: 5px;

        @include media('<lg_992') {
            width: 100%;
            margin-top: 10px;
            margin-left: 0px;
        }

        button {
            @include media('<lg_992') {
                width: 100%;
            }
        }
    }
}

.onboarding {
    &__wrap {
        min-height: 550px;
        min-height: 350px;
        display: flex;
        flex-direction: column;
    }

    &__content {
        padding: 0px 2px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 50px);
        flex: 2;
    }

    &__header {
        margin: auto;
        text-align: center;
        margin-bottom: 2.5rem;
        
        &__title {
            font-weight: 500;
            font-size: 1.25rem;
            color: #171717;
        }
    }

    &__body {
        &__title {
            font-size: 1.15rem;
            font-weight: 600;
        }
    }

    &__usecase__item {
        display: flex;
        border: 1px solid #eee;
        padding: 5px 10px;
        background: #fff;
        font-size: 0.875rem;
        margin-bottom: 10px;
        border-radius: 6px;     

        &.active {
            background: #f1f2ff;
            border: 1px solid #289CA9;
        }

        .form-check-label {
            min-width: 80%;
        }
    }

    &__progress {
        &__item {
            width: 20px;
        }
    }

    &__img {
       background: #f7f8fb;
       padding: 20px 10px;
       display: flex;
       align-items: center;
       justify-content: center;
       border-radius: 10px;
       margin-bottom: 1.5rem;
       border: 1px solid #eee;
    }
}