@import "~@/assets/scss/utils/mixins.scss";

.progress__line {
    width: 100%;
    background-color: #E6E6E6;

    &__inner {
        height:3px;
    }
}
