@import "~@/assets/scss/utils/mixins.scss";

    .avatar {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        flex-shrink: 0;
        width: 1.5625rem;
        height: 1.5625rem;
        width: 2.5rem;
        height: 2.5rem;
        overflow: visible;
        position: relative;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, box-shadow .15s ease-in-out;
        font-weight: 600;
        font-size: 0.75rem;
        text-transform: uppercase;
        font-style: normal;
       
        &-circle {
            border-radius: 50% !important;
            border-radius: 100%;
        }

        &-square {
            border-radius: 5px !important;
        }

        &-solid {
            // background: var(--main-50, #F3F5F1) !important;
            background: rgb(218, 222, 231);
            color: #3F3D56;
            color: var(--neutral-800)
        }

        &-outline {
            border: 1px solid #A0AEC0;
            background: transparent !important;
            color: #A0AEC0;
        }
    }
