.hide {
    display: none !important;
}

.block {
    display: block !important;
}

#app {
    height: 100vh;
    height: 100%;
}

.z--wrapper {
    height: 100%;
}

.w {
    &--100 {
        width: 100% !important;
    }
    &--auto {
        width: auto;
    }
    &--fit {
        width: fit-content !important;
    }
    &--40 {
        width: 40% !important;
    }
    &--50 {
        width: 50% !important;
    }
    &--70 {
        width: 70% !important;
    }
}

.h {
    &--100 {
        height: 100% !important;
    }
    &--auto {
        height: auto !important;
    }
}

.m-w {
    &-50 {
        max-width: 50% !important;
    }

    &-45 {
        max-width: 45%;
    }

    &-100 {
        max-width: 100% !important;
    }

    &-unset {
        max-width: unset;
    }
}

.min-h {
    &-100  {
        min-height: 100%;
    }
    &-inherit  {
        min-height: inherit;
    }
}

.imgCircle {
    border-radius: 50%;
}

.imgResponsive {
    width: 100%;
    object-fit: cover;
    height:auto;
}

.visibilityNone {
    visibility: hidden;
}

.overflow {
    &-visible {
        overflow: visible !important;
    }
}

.row {
    &__left, &__right  {
        display: flex;
        align-items: center;
    }

    &__right {
        &--padded-top {
            padding-top:9px;
        }
    }

    &__item {
        padding: 0 12px 9px;
        
        &:last-child {
            padding-right: 0;
        }
        &:first-child  {
            padding-left: 0;
        }
    }
}

.pointer {
    cursor: pointer;
}

.info-icon {
    vertical-align: middle;
}

.relative {
    position: relative !important;
}

.absolute {
    position: absolute !important;
}

.no--margin {
    &__all {
        margin:0;
    }

    &__x {
        margin-right:0;
        margin-left:0;
    }
    &__y {
        margin-top:0;
        margin-bottom:0;
    }
}

.shadow-none {
    box-shadow:none;
}

.divider {
    &--horizontal {
        width: 53px;
        height: 1.5px;
        background: #b3c7cd;
        margin:19px 0;
        &--full {
            width:100%;
            height:1px;
            background:#ededed;
            margin:0;
        }
        &--light {
            height:0;
            border-top:0.5px solid #f2f2f2;
        }

        &--right, &--left {
            margin:38px 0;
            flex-basis:45%;
        }
    }
    &--vertical {
        width: 1.5px;
        height: 23px;
        background: #b3c7cd;
        margin:0 19px;
        &--light {
            height:48.6px;
            width:1px;
            background:#ededed;
            margin:0;
        }
    }

}

.m {
    &l {
        &--0  {
            margin-left: 0 !important;
        }
        &--5 {
            margin-left:5px !important;
        }
        &--10 {
            margin-left:10px !important;
        }
        &--15 {
            margin-left:15px !important;
        }
        &--20 {
            margin-left:20px !important;
        }
        &--30  {
            margin-left: 30px;
        }
        &--40  {
            margin-left: 40px;
        }
    }
    
    &r {
        &--0 {
            margin-right: 0px !important;
        }
        &--5 {
            margin-right: 5px !important;
        }
        &--10 {
            margin-right: 10px !important;
        }
        &--15 {
            margin-right: 15px !important;
        }
        &--25  {
            margin-right: 25px !important;
        }
        &--35  {
            margin-right: 35px !important;
        }
    }

    &b {
        &--0  {
            margin-bottom: 0px !important;
        }
        &--5 {
            margin-bottom: 5px !important;
        }
        &--10 {
            margin-bottom: 10px !important;
        }
        &--15 {
            margin-bottom: 15px !important;
        }
        &--20 {
            margin-bottom: 20px !important;
        }
        &--25  {
            margin-bottom: 25px !important;
        }
        &--35  {
            margin-bottom: 35px !important;
        }
        &--45 {
            margin-bottom: 45px !important;
        }
    }

    &t {
        &--0  {
            margin-top: 0px !important;
        }
        &--5 {
            margin-top: 5px !important;
        }
        &--10 {
            margin-top: 10px !important;
        }
        &--15 {
            margin-top: 15px !important;
        }
        &--20 {
            margin-top: 20px !important;
        }
        &--30 {
            margin-top: 30px !important;
        }
        &--40 {
            margin-top: 40px !important;
        }
        &--60 {
            margin-top: 60px !important;
        }
        &--80 {
            margin-top: 80px !important;
        }
    }

    &x {
        &--0 {
            margin-right: 0;
            margin-left: 0;
        }
        
        &--auto {
            margin-right: auto;
            margin-left: auto;
        }
    }
}

.p {
    &--0 {
        padding: 0 !important;
    }

    &--5 {
        padding: 5px !important;
    }

    &--10 {
        padding: 10px!important;
    }

    &--15 {
        padding: 15px!important;
    }

    &--20 {
        padding: 20px!important;
    }

    &--25  {
        padding: 25px !important;
    }
    
    &--35  {
        padding: 35px !important;
    }

    &--x--0 {
        padding-right: 0;
        padding-left: 0;
    }
    
    &--y--0 {
        padding-top: 0;
        padding-bottom: 0;
    }

    &b {
        &--0 {
            padding-bottom: 0 !important;
        }
        &--5 {
            padding-bottom:5px;
        }
        &--10 {
            padding-bottom:10px;
        }
        &--12 {
            padding-bottom:12px;
        }
        &--15 {
            padding-bottom:15px;
        }
        &--25 {
            padding-bottom: 25px;
        }
        &--35 {
            padding-bottom: 35px;
        }
    }

    &t {
        &--0 {
            padding-top: 0 !important;
        }
        &--5 {
            padding-top: 5px !important;
        }
        &--10 {
            padding-top: 10px !important;
        }
        &--12 {
            padding-top: 12px !important;
        }
        &--15 {
            padding-top: 15px !important;
        }
        &--20 {
            padding-top: 20px !important;
        }
        &--25 {
            padding-top: 25px !important;;
        }
        &--35 {
            padding-top: 35px !important;;
        }
    }

    &r {
        &--0 {
            padding-right: 0 !important;
        }
        &--5 {
            padding-right: 5px !important;
        }
        &--10 {
            padding-right: 10px !important;
        }
        &--12 {
            padding-right: 12px !important;
        }
        &--15 {
            padding-right: 15px !important;
        }
        &--20  {
            padding-right: 20px !important;
        }
        &--25  {
            padding-right: 25px !important;
        }
        &--35  {
            padding-right: 35px !important;
        }
    }

    &l {
        &--0 {
            padding-left: 0 !important;
        }
        &--5 {
            padding-left: 5px !important;
        }
        &--10 {
            padding-left: 10px!important;
        }
        &--15 {
            padding-left: 15px!important;
        }
        &--20 {
            padding-left: 20px!important;
        }
        &--25  {
            padding-left: 25px !important;
        }
        &--35  {
            padding-left: 35px !important;
        }
    }
}

.text-divider {
    margin-left:5px;
    margin-right:5px;
}

.flex-wrap {
    flex-wrap:wrap;
}

.border {
    &--0 {
        border: none !important;
    }

    &--x--0 {
        border-right:0;
        border-left:0;
    }

    &--y--0 {
        border-top:0;
        border-bottom:0;
    }

    &-b {
        &-light {
            border-bottom: 1px solid #E6E6E6 !important;
        }
    }

    &-r {
        &-light {
            border-right: 1px solid #E6E6E6 !important;
        }
    }

    &-radius {
        &-0  {
            border-radius: 0 !important;
        }
    }

    &-black {
        border: 1px solid black !important;
    }
}

.top {
    &--5 {
        top: 10px;
    }
    &--10 {
        top: 10px;
    }
    &--20 {
        top: 20px;
    }
    &--30 {
        top: 30px;
    }
    &--40 {
        top: 40px;
    }
    &--50 {
        top: 50px;
    }
}

.right {
    &--5 {
        right: 10px;
    }
    &--10 {
        right: 10px;
    }
    &--20 {
        right: 20px;
    }
    &--30 {
        right: 30px;
    }
}

.inline {
    display: inline !important;
}

.flex {
    display: flex !important;

    &-col {
        flex-direction: column;
    }

    &-1 {
        flex: 1
    }

    &-2 {
        flex: 2
    }

    &-basis-45 {
        flex-basis: 45%;
    }

    &-wrap {
        flex-wrap: wrap;
    }
}

.align-items-start {
    align-items: flex-start;
}

.align-items-center {
    align-items: center;
}

.align-items-baseline {
    align-items: baseline;
}

.align-items-end {
    align-items: flex-end;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-evenly {
    justify-content: space-evenly;
}

.justify-content-end {
    justify-content: flex-end;
}

.justify-content-start {
    justify-content: flex-start;
}

.cursor-text {
    cursor: text;
}

.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75% !important;
    font-weight: 500 !important;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.disabled {
    opacity: 0.5;
    cursor:not-allowed !important;
    pointer-events: none;
}

.lh {
    &-16 {
        line-height: 16px;
    }

    &-20 {
        line-height: 20px;
    }

    &-24 {
        line-height: 24px;
    }
}

.bg {
    &-inherit {
        background: inherit !important;
    }
}

// .svg-icon {
//     line-height: 0 !important;
// }
