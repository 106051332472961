@import "~@/assets/scss/utils/mixins.scss";

    .email {
        &--item {
            display: flex;
            align-items: center;
            flex-grow: 1;
            background: #c7d2f5;
            border-radius: 50px;
            padding: 3px 5px;
            justify-content: center;
            font-size: 14px;
            margin-right: 5px;

            span {
                &:first-of-type {
                    margin-right: 5px;
                    margin-left: 5px
                }
            }
        }
        &--input {
            flex-grow: 7;
        }
        &--wrap {
            display: flex;
            align-items: center;
        }
    }
    .half-width {
        max-width: 500px !important;
    }
    .full-width {
        max-width: 100% !important;
    }
