@import "~@/assets/scss/utils/mixins.scss";

.form__input-group {
    border-radius: 6px !important;

    input {
        border: none;
        outline: none;
        background: transparent;
        min-width: 93%;
    }
}
