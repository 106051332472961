.task {
    &__action {
        &--btns {
            visibility: hidden;
        }
    }

    &__completed {
        &__wrap {
            flex: 0 0 auto;
            margin: 0 3px 0 0;
            align-items: center;
            box-sizing: border-box;
            display: inline-flex;
            height: 16px;
            justify-content: center;
            overflow: visible;
            width: 16px;
        }

        &--list {
            padding-right: 50px;
            display: flex;
            align-items: center;
            border-top: 1px solid #edeae9;
        }
        &--icon {
            height: 16px;
            min-height: 16px;
            min-width: 16px;
            width: 16px;
            overflow: hidden;
            margin: -3px;
            padding: 0px;
            display: block;
            stroke-width: 2px;

            path.outer-path {
                fill: #fff;
                stroke: #6d6e6f;
                transition: 200ms fill,200ms stroke;
            }
            path.inner-path {
                fill: #6d6e6f;
                transition: 200ms fill,200ms stroke;
            }
        }
    }

    &__input {
        background: transparent;
        border-radius: 0;
        display: inline-block;
        outline: 0 !important;
        overflow: hidden;
        margin-left: 2px;
        width: 100%;
        white-space: pre;
        padding-bottom: 0;
        padding-top: 0;
        border: none !important;
        width: fit-content;
        padding-top: 0;
        padding-bottom: 0;

        &:focus-within, &:active, &:focus {
            border: none !important;
            outline: 0 !important;
            box-shadow: none !important;
            border-color: transparent !important;
        }

        &--wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 70%;
            max-width: 70%;

            .spinner-border {
                width: 1rem !important;
                height: 1rem !important;
                border-width: 0.1rem !important;
            }
        }
    }

    &__priority {
        &--item {
            cursor: pointer;
            justify-content: center;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            // min-width: 75px;
            align-items: baseline;

            &.selected {
                .task__priority--flag {
                    border: 1px solid #c9cde7;   
                    border-radius: 3px;               
                }

                p {
                    color: var(--brand-500, #289CA9);
                    font-weight: 600;
                }
            }

            p {
                font-size: 14px !important;
                color: #5E6C84;
            }
        }

        &--flag {
            // padding: 10px 5px;
            border: 1px solid transparent;
            // width: 20px;
            // height: 20px;  
            margin-bottom: 5px;
            padding-top: 5px;
            padding-bottom: 5px;

            &.selected {
                border: 1px solid #c9cde7;   
                border-radius: 3px;               
            }
        }
    }

    &__project__title {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &__action__btns {
        border-top: 1px solid #eee;
        padding-top: 10px;
        margin-top: 0px;
    }

    &__item {
        border-bottom: 1px solid #eee;
        padding: 0.5rem 0.625rem;
        cursor: pointer;
        margin-bottom: 0.5rem;
        padding-bottom: 0.2rem;
        padding-top: 0.2rem;

        &__text {
            max-width: 530px;
            text-align: justify;
            font-size: 0.875rem;
        }

        &__deadline {
            color: #BF2C6F;
            font-size: 0.75rem;
        }
        
        &__inline {
            &__input {
                font-size: 0.875rem;
                border: none !important;
                outline: none !important;
                white-space: nowrap;
                transition: color .24s;

                &:focus, &:focus-within {
                    box-shadow: none !important;
                }
        
                &.main {
                    font-weight: 600;
                    color: #202020;
                }
        
                .sub {
                    color: #667085;
                }
            }

            &__btns {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: end;
                border-top: 1px solid #eee;
                padding-top: 10px;
                margin-top: 5px;
            }
        }

        &__label {
            min-width: 89%;
        }
    }

    &__notes {
        &__list {
            height: 200px;
            overflow: scroll;
        }

        &__title {
            width: 100%;
            border-bottom: 1px solid #eee;
            padding-bottom: 10px;
            margin-top: 2rem;
            display: flex;
            align-items: baseline;
        }

        &__item {
            padding-bottom: 0.625rem;
            padding-top: 0.625rem ;
    
            &__text {
                max-width: 530px;
                text-align: justify;
                font-size: 0.875rem;
            }
        }

        &__textarea {
            color: #625f16 !important;
            background: #FAF6EA !important;

            textarea {
                color: inherit !important;
                background: inherit !important;
            }
        }
    }

    &__add {
        &__dropdown {
            min-width: 27%; 

            &__prefix {
                border-radius: 3px;
                height: 8px;
                width: 8px;
                border: 1px solid dodgerblue;
                margin-right: 5px;
                font-size: 0.875rem;
            }
        }
    }
}
