.dropdown {
    position: relative;

    &-menu {
        background: #fff;
        text-align: left;
        z-index: 9999;
        min-width: auto !important;
        
        .show {
            display: block;
        }
    }

    &-form {
        border: 1px solid #ddd;
        padding: 3px 10px;
        border-radius: 5px;
        padding: 0.3rem 0.75rem 0.3rem 0.75rem;
        display: flex;
        margin: 12px;
    
        input {
            outline: none;
            border: none;
            width: 80%;
            font-size: 0.875rem;
            flex: 2;
        }

        >span {
            width: 10%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex: 1;
        }
    }

    &-item.active, &-item:active {
        color: #1e2125 !important;
        background-color: #e9ecef !important;
    }

    &-btn {
        border: 1px solid #ced4da !important;
        text-align: left;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        line-height: 1.5;
        background-color: #fff;
        background-clip: padding-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: .25rem !important;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        padding: 2px 5px !important;
        font-size: 0.875rem !important;

        &-sm {
            padding: .25rem .5rem !important;
        }

        &-md {
           padding: 0.5625rem 0.75rem !important;
        }
        
        &-addon {
            display: flex;
            align-items: center;
            padding: .375rem .75rem;
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1.5;
            color: var(--bs-body-color);
            text-align: center;
            white-space: nowrap;
            background-color: #f8f9fa;
            margin-right: 0.5rem;
            border-radius: 0.375rem;
        }

        .dropdown--item-desc {
            // margin-right: 5px;
            text-align: left;
        }
    }

    &--item {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0.45rem 1rem !important;
        cursor: pointer;

        &-label {
            font-size: 0.875rem;
            width: 100%;
            max-width: 100%;
            overflow: hidden;
            white-space: pre-wrap;
        }

        &-title {
            color: #1a1f36;
            font-weight: 500;
        }

        &-subtitle {
            color: #697386;
        }

        &.selected {
            color: #1e2125;
            background-color: #e9ecef;
            font-weight: 600;
        }

        &.no-hover {
            &:hover, &:focus {
                background-color: transparent !important;
            }
        }
    }

    &-list {
        padding-left: 0px;
        padding-right: 0px;
        height: 200px;
        overflow: scroll;
    }

    &-toggle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &:after {
            display: none !important;
            margin-left: .255em;
            vertical-align: .255em;
            content: "";
            border-top: .3em solid;
            border-right: .3em solid transparent;
            border-bottom: 0;
            border-left: .3em solid transparent;
        }
    }

    &--disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
    }

    &--menu {
        position: absolute;
        top: 0px;
        right: auto;
        bottom: auto;
        left: 0px;
        margin: 0px;
        transform: translate3d(0px, 40px, 0px);
        // width: 100%;
        background: #fff;
        text-align: left;
        z-index: 9999;
        box-shadow: 0px 18px 36px rgba(0, 0, 0, 0.08);
        border-radius: .5rem !important;
        padding: 0.5rem 0;
        border: 1px solid rgba(0, 0, 0, 0.175);

        .show {
            display: block;
        }
    }

    &--title {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
    }

    &--selection {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .dropdown--item--multiple {
            color: #212529;
            font-size: 0.875rem;
            font-weight: 400;
            background: #e5e9ec;
            border-radius: 89px;
            width: auto;
            height: auto;
            display: flex;
            padding: 3px 8px;
            margin-bottom: 5px;
            justify-content: space-between;
            align-items: center;

            &:not(:last-of-type) {
                margin-right: 5px;
            }
        }
    }
}

.project-dropdown {
    font-size: 0.75rem !important;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    white-space: nowrap;
}