.tabs {
  display: grid;
  grid-template-columns: 1fr;

  .tab-list {
    list-style: none;
    display: flex;
    width: fit-content;
    align-items: center;

    &.left {
      justify-content: flex-start;
    }

    &.center {
      justify-content: center;
    }

    &.right {
      justify-content: flex-end;
    }

    &.line {
      padding-left: 0;
      width: 100%;
      border-bottom: 1px solid var(--gray-300, #E0E0E0) !important;

      .tab-list__item {
        padding: 5px 10px 5px 0px;
  
        a {
          color:#414552 !important;
  
          &.active {
            font-weight: 700 !important;
            color:var(--brand-500, #289CA9) !important;
          }
        }
  
        &:not(:first-child) {
          margin-right: 10px;
        }
  
        &[aria-selected="true"] {
          border-bottom: 2px solid var(--brand-500, #289CA9) !important;
          color:var(--brand-500, #289CA9) !important;
          font-weight: 600;
          border-radius: 2px;
        }
        
        &[aria-disabled="true"] {
          cursor: not-allowed;
          color: var(--disabled-text-color);
        }
      }
    }

    &.pill {
      border: none !important;
      background: #f4f2f2;
      border-radius: 20px;
      padding: 5px 4px !important;

      .tab-list__item {  
        font-weight: 500;
        color:#414552;
        font-size: 0.75rem !important;
        padding: 2px 10px;

        &.active {
          background: #fff;
          border-radius: 20px;
        }
        
        &[aria-disabled="true"] {
          cursor: not-allowed;
          color: var(--disabled-text-color);
        }
      }
    }

    &__item {
      cursor: pointer;
      user-select: none;
      position: relative;
      font-size: 0.85rem;
      
      &:not(:last-of-type) {
        margin-right: 10px;
      }
    }
  }
}

.tab {
    margin-top: 1rem;

    &-list {
      padding: 0px 20px 0px 20px;
      margin-bottom: 0px;
    }

    &--panel, &-panel {
      border-radius: 3px;
      background-color: #fff;
      width: 100%;
      // margin-bottom: 30px;
    }
}