@import "~@/assets/scss/utils/mixins.scss";

    .tag {
        border-radius: 50%;
        height: 5px;
        width: 5px;
        padding: 0.1rem;

        &--cornsilk {
           background:  rgb(210, 105, 30)!important;
        }
   
        &--purple {
            background: #6b21a8 !important;
        }
    
        &--pink {
            background: #DA7DA0 !important;
        }
    
        &--red {
            background: rgb(144, 22, 69) !important;
        }
        
        &--green {
            background: #228d2d !important;
        }
    
        &--blue {
            background: #1e40af !important;
        }
    
        &--yellow {
            background: #e8bd0d !important;
        }
    
        &--grey {
            background: #2c3234 !important;
        }
    }
