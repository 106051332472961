@import "~@/assets/scss/utils/mixins.scss";

.logo {
    &__wrap {
        margin-bottom: 1rem;
        display: flex;
        cursor: pointer; 
        padding: 10px 20px;  
        
        &.left {
            width: 100%;
            justify-content: flex-start;
        }

        &.center {
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
            justify-content: center;
        }
    }
}
