@import "~@/assets/scss/utils/mixins.scss";

    .empty {
        &--wrap {
            max-width: 550px;
            margin: 6rem auto;
        }

        &--image {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        &--text {
            text-align: center;
            margin-top: 1.25rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &--title {
            font-size: 1.25rem;
            color: #1a1f36;
            font-weight: 600;
        }
        
        &--subtitle {
            color: #697386;
            font-size: 0.875rem;
            max-width: 390px;

            &.error {
                max-width: 440px;
            }
        }
    }  
