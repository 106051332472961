<!-- MultiSelectDropdown.vue -->
<template>
    <div 
        v-click-outside="closeDropdown" 
        class="multi-select-dropdown"
        :class="{ 'is-open': isDropdownOpen }"
    >
        <!-- Dropdown Trigger Button -->
        <div class="dropdown-trigger" @click="toggleDropdown">
            <div class="selected-items">
                <template v-if="value.length">
                    <span 
                        v-for="(item, index) in selectedItems" 
                        :key="index" 
                        class="selected-tag"
                    >
                        {{ item[labelKey] }}
                        <span 
                            class="remove-tag" 
                            @click.stop="removeItem(item)"
                        >&times;</span>
                    </span>
                </template>
                <span v-else class="placeholder">{{ placeholder }}</span>
            </div>
            <span class="dropdown-arrow">▼</span>
        </div>
  
        <!-- Dropdown List -->
        <transition name="fade">
            <div v-if="isDropdownOpen" class="dropdown-list">
                <!-- Search Input -->
                <input 
                    v-model="searchQuery" 
                    type="text" 
                    :placeholder="searchPlaceholder"
                    class="search-input"
                >
  
                <!-- Filtered List -->
                <div class="list-container">
                    <div 
                        v-for="item in filteredItems" 
                        :key="item[valueKey]"
                        class="dropdown-item"
                        @click="toggleSelection(item)"
                    >
                        <input 
                            type="checkbox" 
                            :checked="isSelected(item)"
                        >
                        <span>{{ item[labelKey] }}</span>
                    </div>
            
                    <!-- Empty State -->
                    <div 
                        v-if="filteredItems.length === 0" 
                        class="no-results"
                    >
                        No results found
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
  
<script>
import _ from "lodash";
  
export default {
    name: "MultiSelectDropdown",
    directives: {
        // Click outside directive
        "click-outside": {
            bind(el, binding) {
                const handler = (e) => {
                    if (!el.contains(e.target)) {
                        binding.value();
                    }
                };
                el.clickOutsideEvent = handler;
                document.addEventListener("click", handler);
            },
            unbind(el) {
                document.removeEventListener("click", el.clickOutsideEvent);
            },
        },
    },
    props: {
        // V-model support for two-way binding
        value: {
            type: Array,
            default: () => [],
        },
        // List of selectable items
        items: {
            type: Array,
            required: true,
        },
        // Key to use for unique identification
        valueKey: {
            type: String,
            default: "_id",
        },
        // Key to use for display label
        labelKey: {
            type: String,
            default: "name",
        },
        // Placeholder text
        placeholder: {
            type: String,
            default: "Select items",
        },
        // Search placeholder
        searchPlaceholder: {
            type: String,
            default: "Search...",
        },
    },
    data() {
        return {
            isDropdownOpen: false,
            searchQuery: "",
            selectedItems: [],
        };
    },

    computed: {
        // Filtered items based on search query
        filteredItems() {
            return this.items.filter(item => 
                item[this.labelKey].toLowerCase().includes(this.searchQuery.toLowerCase()),
            );
        },
    },
    
    methods: {
        // Toggle dropdown visibility
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
  
        // Close dropdown
        closeDropdown() {
            this.isDropdownOpen = false;
            this.searchQuery = ""; // Reset search
        },
  
        // Check if an item is selected
        isSelected(item) {
            return this.value.some(
                selectedItem => selectedItem[this.valueKey] === item[this.valueKey],
            );
        },
  
        // Toggle item selection
        toggleSelection(item) {
            const itemExists = this.isSelected(item);
            let newValue;
  
            if (itemExists) {
                // Remove item if already selected
                newValue = this.value.filter(
                    selectedItem => selectedItem[this.valueKey] !== item[this.valueKey],
                );
            } else {
                // Add item if not selected
                newValue = [...this.value, item];
            }
  
            // Emit input event for v-model
            this.$emit("input", newValue);
        },
  
        // Remove specific item
        removeItem(item) {
            const newValue = this.value.filter(
                selectedItem => selectedItem[this.valueKey] !== item[this.valueKey],
            );
            this.$emit("input", newValue);
        },
    },
};
</script>
  
  <style scoped>
  .multi-select-dropdown {
    position: relative;
    width: 300px;
    font-family: Arial, sans-serif;
  }
  
  .dropdown-trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
  }
  
  .selected-items {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    max-height: 100px;
    overflow-y: auto;
  }
  
  .selected-tag {
    background-color: #f0f0f0;
    padding: 2px 5px;
    border-radius: 3px;
    display: flex;
    align-items: center;
  }
  
  .remove-tag {
    margin-left: 5px;
    color: red;
    cursor: pointer;
  }
  
  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: white;
    z-index: 10;
  }
  
  .search-input {
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
  }
  
  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  
  .no-results {
    padding: 10px;
    text-align: center;
    color: #888;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>