.settings {
    &__row {
        &__between {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__item {
            border-bottom: 1px solid #E6E6E6;
            padding-bottom: 12px;
        }
    }

    &__auth {
        &__header {
            margin-top: 2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 0.5rem;
            border-bottom: 1px solid #E6E6E6;

            p {
                color: var(--grey-300);
                font-size: 1rem;
                font-weight: 600;
            }

        }
    }

    &__sub {
        &__header {
            padding: 0.75rem 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #E6E6E6;
        }
    }

    &__integrations {
        &__card {
            &__item {
                max-width: 30%;
                width: 30%;
                margin-right: 1rem;
                margin-bottom: 1.5rem;
                position: relative;

                .card:hover {
                    cursor: pointer;
                    border: 1px solid #aeb4bb;
                }
            }

            &__btn {
                position: absolute;
                right: 5px;
                top: 5px;

                &.standard {
                    color: #4299E1 !important;
                }

                &.standard {
                    color: #289CA9 !important;
                }
            }
        }
    }

    &__page {
        height: 100%;
        position: relative;
    }
}

.cr {
    &__list {
        margin-top: 1rem;
        // max-width: 40%;

        &__item {
            position: relative;
            display: flex;
            min-width: 0;
            word-wrap: break-word;
            background-color: #fff;
            background-clip: border-box;
            border: 1px solid rgba(0, 0, 0, .125);
            border-radius: .5rem;
            padding: 15px;
            margin: 1rem 0;
            cursor: pointer;
        }
    }
}

.zm {
    &__count__badge {
        margin-right: 4px;
        border: 1px solid #e5e5e5;
        padding: 2px 6px;
        border-radius: 50%;
        font-size: 14px;
        color: #757676;
        color: #7d6ab9;
        background: #f3efff;
        border: 1px solid #7d6ab9;

    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        margin-top: 2rem;

        &__left {
            flex: 1;
            max-width: 45%;
            margin-right: 2rem;

            &.empty {
                flex: unset;
                max-width: 100%;
                width: 100%;
                margin-right: 0rem;
            }
        }

        &__right {
            flex: 2;
            max-width: 55%;
        }
    }

    &__list {
        &__item {
            &.active {
                border: 1px solid #289CA9;
            }

            &__details {
                border: 1px solid rgba(0, 0, 0, .125);
                border-radius: .5rem;
                padding: 15px 23px;
                position: relative;
                min-width: 0;

                .meta__date, .meta__item {
                    margin-top: 1rem;
                }

                .title {
                    font-weight: 600;
                    font-size: 24px;
                    color: #09090B;
                }

                .meta {
                    &__note {
                        border-bottom: 1px solid #eee;
                        padding-bottom: 10px;
                        align-items: center !important;
                        justify-content: space-between !important;
                        display: flex !important;
                        margin-bottom: 5px !important;
                    }
                }
            }
        }
    }
}

.activity {
    &-header {
        justify-content: space-between;
        width: 100%;

        h5 {
            font-size: 18px;
        }
    }

    &__item {
        margin-bottom: 1rem;
        padding: .5rem;

        &__wrap {
            &__icon {
               max-width: 100%;
            }

            &__text__content {
                min-width: 85%;

                .msg {
                    font-size: 0.75rem;
                    color: var(--grey-300) !important;
                    font-weight: 500 !important;
                    max-width: 278px;
                    word-wrap: break-word;
                    margin-bottom: 5px;
                }
            }
        }

        &__icon {
            width: 1.5625rem;
            height: 1.5625rem;
            border-radius: 50%;
            overflow: visible;
            position: relative;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,box-shadow .15s ease-in-out;
            font-weight: 600;
            line-height: 16px; /* 133.333% */
            font-size: 12px;display: inline-flex;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
            flex-shrink: 0;
            padding: 1.2rem;
            margin-right: 0.75rem;
            align-self: flex-start;

            &.create {
                background: #dbf0d4;
            }
            
            &.edit {
                background: #e2f2ff;
            }

            &.status {
                background: #dccef9;
            }
            
            &.delete {
                background: #facfd7;
            }
        
            &.others {
                background: #ecc7b3;
            }
        }

        &__link {
            text-decoration: underline !important;
            color: #757676;
            font-size: 0.75rem;
            font-weight: 600;
            cursor: pointer;
            margin-bottom: 8px;
            margin-top: 8px;
        }
        
        &__date {
            margin-top: 3px;
        }
    }
    
    &__unread__icon {
        padding: 4px !important;
        top: 12px !important;
        left: 67% !important;
        background-color: #BF2C6F !important;
    }
}