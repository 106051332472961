@import "~@/assets/scss/utils/mixins.scss";

    .row.hidden-xs {
        border-bottom: 1px solid #f5f5f5;
        margin-bottom: 2rem;
    }

    .form__row__left {
        max-width: 500px;
    }

    .invoice__single--btns {
        .row__item {
            padding-right: 0px !important;
        }
    }
