@import "~@/assets/scss/utils/mixins.scss";

    .action--btns__wrap {
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;
        padding-top: 10px;
        background-color: #f6f8fa !important;
    }

    .dropdown-menu.show {
        transform: translate3d(-10px, 30px, 0px) !important;
        transform: translate3d(0px, 30px, 0px) !important;
        width: 300px !important;
        inset: 0px 0px auto auto !important;
        padding: 0px !important;
        box-shadow: inset 0 -1px rgba(235, 238, 241, 0) !important; 
        border-radius: 5px;
   }

   .filter {
        &-input {
            font-size: 12px !important;
            border-radius: 6px;
        }

        &-item-input {
            display: flex;
            flex-direction: column;
            padding: 10px 11px;

            &:not(:last-of-type) {
                border-bottom: 1px solid #eee;
            }

            &:last-of-type {
                padding-bottom: 0px;
            }

            label {
                font-size: 12px !important;
            }
        }

        &-status {
            &-item {
                flex-direction: row !important;
                flex-wrap: wrap;

                label {
                    margin-bottom: 0px !important;
                }
            }
        }
   }
