.project {
    &__page--view {
        padding-right: 35px; 
        padding-left: 35px; 
        padding-top: 20px;
    }

    &__group {
        padding: 5px 0;
        margin-bottom: 0.75rem;

        &:not(:last-of-type) {
            border-bottom:1px solid #cecece !important;
        }

        p.title {
            color: #202020;
            font-size: 0.875rem;
            font-weight: 600;
            margin-bottom: 0.75rem !important;
            text-transform: capitalize;
        }
    }

    &__details {
        &__header {
            margin-top: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 0.75rem;
            border-bottom: 1px solid #E6E6E6;

            p {
                color: var(--grey-700);
                font-size: 1rem;
                font-weight: 600;
            }
        }

        &__row {
            &__left {
                padding-left: 0.5rem !important;
                padding-right: 0.5rem !important;
            }
        }
    }

    &__row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
   
    &__item {
        width: 100%;
        margin-bottom: 0.5rem;

        &--wrap {
            display: flex;
            margin-top: 1.2rem;
            flex-wrap: wrap;
            width: 100%;
        }

        &__tags {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        &__flex {
            display: flex;
            align-items: center;
        }

        &__left, &__right {
            flex: 1;
            max-width: 45%;
            width: 45%;
        }
        
        &.email {
            align-items: flex-end !important;
        }

        &__title {
            color: #667085;
            font-size: 0.875rem;
            font-weight: 500;
        }

        &__desc {
            color: #202020;
            font-size: 0.875rem;
            margin-top: 4px;
            // text-transform: capitalize;
        }

        &__left {
            margin-right: 1.5rem;

            &__header {
                padding: 15px 20px 20px;
                background: #f7f7f7 !important;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            &__body {
                padding: 15px 20px;
            }

            .sub__tag {
                display: inline !important;
                vertical-align: middle !important;
                align-items: center;
                margin-left: 0px;
                border: 1px solid #cecece !important;
                border-radius: 20px;
                background: white !important;
                padding: 4px 10px;
                font-size: 0.875rem;

                &:not(:last-of-type) {
                    margin-right: 5px;
                }
            }
        }

        &__action__btns {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        span.title, span.sub {
            color: #202020;
            font-size: 0.875rem;
            font-weight: normal;
        }

        span.sub {
            font-weight: 500 !important;
        }

        &__meta {
            margin-top: 1.5rem;
        }
    }
    
    &__header {
        &__status {
            display: flex;
            align-items: center;
        }

        &__wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            width: 100%;
        }
    }
    
    &__notes {
        margin-top: 2rem;

        &__item {
            padding-bottom: 1rem;
            padding-top: 1rem;
            border-bottom: 1px solid #E6E6E6;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        &__form {
            height: auto;
        }

        &__action__btns {
            display: flex;
            margin: 10px 1rem 10px 0;
            justify-content: flex-end;
            width: 100%;
            padding-right: 1rem;
        }
    }

    &__sub {
        &__wrap {
            background: #f8f9fa;
            padding: 10px;
            border: 1px solid #e9ecef;
            border-radius: 5px;
            max-height: 200px;
            overflow: scroll;
        }
        
        &__item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 0;
            
            &:not(:last-of-type) {
                border-bottom: 1px solid #eee;
            }

            .title {
                font-size: 0.875rem;
                color: #495057;
                width: 270px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }

        &-border-grey {
            background: transparent !important;
            border: 1px solid #ced4da !important;
            padding: 3px 5px;
            border-radius: 6px;
        }
    }

    &__tags {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;

        >.tag {
            &:not(:last-of-type) {
                margin-bottom: 10px;
            }
        }
    }
}