@import "~@/assets/scss/utils/mixins.scss";

    .dropdown-menu {
        min-width: 12rem !important;
        padding: 0.5rem 1rem;

    }
    .report--item {
        padding: 10px;
        padding-bottom: 5px;
        border-top: 1px solid #e0e1e4;
        padding-top: 15px;
    }
    .zm__count__badge {
        margin-right: 0px;
        border: 1px solid #e5e5e5;
        padding: 1px 4px;
        border-radius: 50%;
        font-size: 8px;
        color: #757676;
    }
