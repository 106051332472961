.nav {
    display: flex;
    justify-content: space-between;
    height: 100%;
    box-shadow: 0 1px 4px 0 rgba(21, 7, 38, 0.08);

    @media screen and (min-width: 1350px) {
        padding-bottom: 44px;
    }

    @media screen and (min-width: 1200px) {
        left: 0;
        width: 220px;
        width: fit-content;
    }

    @media screen and (min-width: 768px) {
        left: 0;
        width: 220px;
        width: fit-content;
    }

    &-pills .nav-link.active, &-pills .show>.nav-link {
        color: #3F3D56 !important;
        background-color: #e6e7eb !important;
        font-weight: 600;
        font-size: 0.875rem;
    }

    &-pills .nav-link, &-pills {
        color: #3F3D56 !important;
        background-color: transparent !important;
        font-weight: 500;
        font-size: 0.875rem;
    }
}

.sidebar {
    position: relative;

    &__nav {
        position: fixed;
        top: 0;
        bottom: 0;
        padding-bottom: 10px;
        flex-direction: column;
        overflow: scroll;
        z-index: 2;
        transition: all 0.3s ease-in-out;
        height: 100%;
        background-color: #fff;

        &__brand {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 8px;
            margin-top: 10px;
        }

        &.collapse-nav {
            width: 40px;
        }

        &.expand-nav {
            width: 220px;

            .sidebar__nav__brand {
                height: 60px;
            }
        }

        &__toggle {
            background: #f1f1f1 !important;
            color: #f2f2f2 !important;
            cursor: pointer;
            padding: 5px;
            border-radius: 50%; 
        }
    }

    &__section {
        width: 100%;
        height: 100%;

        &__title {
            font-size: 14px;
            font-weight: $font-semi-bold;
            margin-bottom: 10px !important;
            color: var(--grey-300);
            padding: 0 8px;
            font-family: $font-stack-geist;
        }

        &__item {
            display: flex !important;
            align-items: center;
            color: var(--grey-600);
            cursor: pointer;
            min-height: 24px;
            -webkit-box-align: center;
            width: 100%;
            font-size: 14px;
            padding: 8px;
            border-radius: 6px;

            svg {
                fill: var(--grey-600);
            }

            &__title {
                font-size: 0.875rem;
                font-weight: $font-normal;
            }
            
            &:hover {
                background-color: #E3F2F4;
                // background-color: #dddedf;
                color:  var(--brand-600) !important;

                svg {
                    fill: var(--brand-600);
                }
            }

            &.router-link-exact-active, &.active {
                background-color: #E3F2F4;
                // background-color: #d5f1f5;
                // background-color: #dddedf;

                span {
                    color: $brand-color;
                    font-weight: 500;
                    color: var(--brand-500) !important;
                }

                svg {
                    fill: var(--brand-500) !important;
               }
            }
        }

        &__links {
            margin-top: 1rem;
            height: calc(100% - 70px);
            display: flex;
            flex-direction: column;
        }
        
        &__group {
            max-width: 220px;
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 1rem;
            margin-top: 1.5rem;

            &:not(:last-child) {
              border-bottom: solid 0.2px #eee;
            }


            &__title{
                font-size: 0.875rem;
                font-weight: $font-normal;
                text-transform: capitalize;
                font-family: $font-stack-geist;
            }
        }
    }

    &__icon {
        width: 1rem;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        display: block;
    }

    &-content {
        &-one {
            flex-grow: 3;
            width: 100%;
        }

        &-settings {
            flex: 1;
            width: 100%;
            margin-bottom: 1rem 
        }
    }

    &__award {
        &__rank {
            border: 1px solid #ebebeb;
            max-width: 200px;
            margin: auto;
            display: flex;
            align-items: center;
            border-radius: 6px;
            padding: 15px 10px;
            cursor: pointer;
        }

        &__score {
            &__title {
                color: var(--grey-700);
                font-weight: 600;
                font-size: 13px;
            }

            &__desc {
                color: var(--grey-300);
                font-weight: normal;
                font-size: 12px;
            }
        }

        &__icon {
            margin-right: 7px;
        }
    }
}