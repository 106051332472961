.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: none;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    background-color: rgba(0, 0, 0, 0.6);
    background-color: rgba(17, 39, 42, 0.22);
    animation: fadeIn 0.3s ease-in-out;

    .align-left {
        margin-right: auto;
        margin-left: 0;
    }

    .center {
        margin: auto;
    }

    .align-right {
        margin-left: auto;
        margin-right: 0;
    }

    &.show {
        display: flex;
    }

    &.show &__dialog {
        -webkit-transform: none;
        transform: none;
    }

    &__header {
        overflow: hidden;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &.with__title {
            justify-content: space-between;
            box-shadow: inset 0 -1px #ebeef1;
        }

        &__title {
            font-weight: 600;
            margin: 0;
            line-height: 1.5;
            font-size: 0.875rem;
            color: #3F3D56;
            text-transform: capitalize;
        }
    }

    &__dialog {
        position: relative;
        margin: 3rem auto 5rem auto;
        background: white;
        border: 1px solid transparent;
        border-radius: 20px;
        outline: 0;
        box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.08), 0 31px 41px 0 rgba(33, 43, 54, 0.2);
        padding: 0px;
        max-width: 960px;
        height: auto;
        width: auto;

        &--sm {
            width: 400px !important;

            @media screen and (max-width: 450px) {
                width: 300px;
            }
        }

        &--md {
            width: 650px !important;

            @media screen and (max-width: 800px) {
                width: 500px;
            }
        }

        &--lg {
            width: 990px;

            @media screen and (max-width: 1000px) {
                width: 768px;
            }
        }
    }

    &.in &__dialog {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    &__body {
        position: relative;
        flex: 1 1 auto;
        padding: 1rem;
        animation: slideIn 0.3s ease-in-out;
        height: 100%;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        padding: 12px 15px;
        text-align: right;
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(64 68 82 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
        font-size: 18px;
        font-weight: 500; 

        .btn + .btn {
            margin-bottom: 0;
            margin-left: 5px;
            min-width: 65px
        }

        .btn-block + .btn-block {
            margin-left: 0;
        }
    }
}

.close {
    border: none;
    background-color: transparent;
    cursor: pointer;
    
    &:active, &:focus {
        outline: none;
        box-shadow: none;
    }
}