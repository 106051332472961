.content {
    background: #f6f8f9;

    &__layout {
        display: flex;
        flex: 1;
        flex-direction: column;
        min-width: 920px;
        position: relative;
        border: 1px solid red
    }

    &__body {
        min-height: calc(100vh - 72px);
        height: 100%;
        // padding-top: 3px;
    }

    &__view {
        border-radius: 8px;
        background-color: #fff;
        -webkit-box-shadow: 0 1.2px 5.6px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 0.83px 1.6px 0 rgba(0, 0,0, 0.12);
        width: 100%;
        border: 1px solid rgba(193, 163, 249, 0.25);
        background-color: #ffffff;
        box-shadow: 0 1.2px 2.6px 0 rgb(0 0 0 / 12%);
        border-width: 1px;
        border-style: solid;
        border-color: #ffffff;
        height: 100%;
        min-height: inherit;
        padding: 25px;
        margin-top: 10px;
    }

    &__page {
        height: 100%;
        position: relative;

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__body {
            width: 100%;
            height: 100%;
            margin-top: 2.5rem;

            &.empty {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            &.side-join {
                display: flex;
                align-items: center;
            }
        }

        &__search {
            min-width: 300px;
        }
    }

    &__inner {
        min-height: inherit;
        padding-bottom: 25px;
    }
}

.pagination {
    &__link {
        padding: 2px 10px !important;
        font-size: 14px;
        position: relative;
        display: block;
        color: #1a1b25;
        cursor: pointer;
        border-radius: 5px;
        text-decoration: none;
        background-color: #fff;
        border: 1px solid #dee2e6;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

        &.active {
            box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
            border-radius: 4px;
            color: var(--brand-500, #289CA9);
            z-index: 3;
        }
    }
}
