@import "~@/assets/scss/utils/mixins.scss";

.text--tag {
    border: 1px solid #eee;
    border-radius: 50rem !important;
    padding: 5px 8px;
    display: inline !important;
    vertical-align: middle !important;
    color: #64748B;
    font-size: 0.75rem;
}

.integration__tier {
    display: flex;
    align-items: center;
    background: #e1e6f6;
    border-radius: 50px;
    padding: 3px 8px;
    justify-content: center;
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 10px;
    width: fit-content;
    text-transform: capitalize;
}
