@import "~@/assets/scss/utils/mixins.scss";

    .dropdown-item {
        display: flex;
        align-items: baseline;
        cursor: pointer;

        &:last-of-type {
            padding-bottom: 0.1rem !important;
        }
    }
