@charset 'UTF-8';

.auth {
    &__wrap--center {
        position: relative;
        // display: flex;
        margin: 3rem auto 0;
        height: 100%;
        // width: 100%;
        // flex-direction: column;
        // align-items: center;
    }

    &__bg {
        background-color: #f7fafc;
        height: 100%;
        max-height: 100vh;
        height: 100vh;
        overflow-y: auto;
        overflow-y: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    &__wrapper {
        z-index: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 0 auto;
        max-width: 1080px;
    }

    &--checkbox {
        margin-top: 1.2rem;
    }

    &__title {
        color: #020202;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 110%;
        margin-bottom: 0.5rem;
        text-align: center;

        &-wrap {
            margin-bottom: 2rem;
            text-align: left;
        }
    }

    &__subtitle {
        color: #373f45;
        opacity: 0.7;
        font-size: 0.875rem;
        font-weight: 400;
    }

    &__form {
        &__wrap {
            margin-top: 1.5rem;
            border: 1px solid #eee;
            background-color: #fff;
            border-radius: 15px;
            padding: 23px 30px;
            padding: 30px 30px 20px;
            width: 430px;
            max-width: 430px;
        }
        
        &__text {
            margin-bottom: 2rem;
        }

    }

    &--form {
        &__wrap {
            padding: 20px 0px 30px;
        }

        &__link {
            text-decoration: none;
            display: inline;
            text-decoration: underline !important;
            font-size: 12px !important;
        }

        &__password__checks {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            margin-top: 10px;
            margin-bottom: 10px;

        }

        &__logo {
            width: 420px;
            max-width: 420px;
            margin-bottom: 2rem;
        }
    }
}

.signup--notify__wrap {
    margin-top: 1rem;
    display: flex;
    justify-content: center;

    a {
        color: var(--brand-500, #289CA9);
        text-decoration: none;
        font-weight: 500;
    }
}

.or-1thbq71 {
    font-family: "Charlie Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, "Droid Sans", "Helvetica Neue", sans-serif;
    margin-bottom: 20px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    color: rgba(66, 82, 110, 1);
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
    width: 100%;
}

.or-rj288j, .or-1y5oso0 {
    border-bottom: 1px solid rgba(216, 216, 216, 1);
    width: 160.6px;
    margin-right: 8px;
}

.or-1y5oso0 {
    margin-left: 8px;
}