.report {
    &__wrap {
        height: 100%;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__item {
        display: flex;
        
        &__wrap {
            padding-top: 0.875rem;
            height: 100%;
            padding-bottom: 1rem;
        }

        &__header {
            border-bottom: 1px solid #d9d6d6;
            padding: 10px 0px;
            // margin-bottom: 20px;
            padding-left: 15px;
            padding-right: 15px
        }

        &__status {
            display: flex;
            align-items: center;
            margin-top: 35px;
            
            >div {
                flex: 1;
            }
        }

        &__type {
            cursor: pointer;
            text-transform: capitalize;
            width: 100%;
            font-size: 0.875rem;

            &.selected {
                color: #289CA9 !important;
                font-weight: 600;
            }
        }

        &__progressbar {
            left: 11% !important;
            bottom: 48% !important;
            display: flex;
            flex-direction: column;
            // justify-content: center;
            // align-items: center;

            .title {
                font-size: 1.25rem;
                font-weight: 600;
                align-self: center;
            }

            .subtitle {
                max-width: 85px;
                margin-top: 5px;
                font-size: 12px !important;
            }
        }
    }

    &__client {
        &__status {
            display: flex;
            align-items: center;
            margin-top: 12px;
            background: #f6f6f6;
            padding: 1rem 1rem 0.5rem;
            flex-wrap: wrap;
            justify-content: space-evenly;
            border-radius: 8px;

            &__item {
                width: fit-content;
                min-width: 100px;
                // border: 1px solid red;
                flex: 1;
                margin-bottom: 10px;
            }
        }
    }
}

.stats {
    &__rate {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 0.75rem;
        max-width: fit-content;
        padding: 2px 5px;
        border-radius: 4px;

        &.red {
            background: #e4bfce !important;
            color: #DA7DA0 !important;
            fill: #DA7DA0 !important;
        }

        &.green {
            background: rgb(217, 237, 219, 0.3) !important;
            color: #13771d !important;
            fill: #13771d !important;
        }

        &.blue {
            background: rgb(219, 234, 254, 0.3) !important;
            color: #1e40af !important;
            fill: #1e40af !important;
        }

        &.green {
            background: rgb(217, 237, 219, 0.7) !important;
            color: #13771d !important;
            fill: #13771d !important;
        }
    }
}