table {
    width: 100%;
    border-collapse: collapse;
}

thead {
    display: table-header-group;
}

tr {
    display: table-row;
}

.counter::before {
    counter-increment: rowNumber;
    content: counter(rowNumber);
}

tbody {
    counter-reset: rowNumber;
}

.table > tbody > tr:hover > * {
    background: #f6fafc;
}

.table {
    border-radius: 0px;
    padding: 0px;
    background-color: rgb(255, 255, 255);
    border-top: none;
    border-collapse: initial;
    box-shadow: rgb(235 231 235 / 50%) 0px -1px 0px 0px inset;
    table-layout: auto;

    &>:not(caption)>*>* {
        font-size: 14px;
    }

    tbody {
        tr, a, span {
            color: rgb(0, 0, 0);
        }

        a.dropdown-item {
            color: #3c4257 !important;

            &:active {
                color: #3c4257 !important;
                background-color: #eee;
            }
        }

        td {
            transition: all 0.3s ease-in-out;
            vertical-align: middle;
            overflow-x: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding: 0.75rem 0;
            background-color: #fff;
            padding-left: 0.25rem;

            &.first {
                max-width: 197px;
                overflow-x: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-left: 0.25rem 
            }
        }

        a, span {
            display: table-row;
            vertical-align: inherit;
            border-color: inherit;
            border-style: solid;
            border-width: 0;
        }
    }

    &-checkbox.indeterminate[type=checkbox] {
        // background-color: #0d6efd;
        // border-color: #0d6efd;
        border-color: #289CA9 !important;
        background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e") !important;
        --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e") !important;
        background-color: #289CA9 !important;
    }

    &.default {
        th {
            &:first-of-type {
                padding-left: 20px;
            }

            &.header {
                font-weight: 600;
                display: table-cell;
                font-size: .875rem;
                vertical-align: middle;
                border-bottom: 1px solid rgb(222, 226, 230);
                // font-family: Public Sans,sans-serif;
                line-height: 1.5rem;
                padding: 0.25rem 0;
                text-align: left;
                padding-left: 0.25rem;

                span {
                    color: #637381;
                }

                svg {
                    fill: #637381;
                } 
            }
        }
    }

    &.striped {
        color: #666;
        font-size: .875rem;
        line-height: 1.25rem;
        caption-side: bottom;
        font-family: $font-stack-geist;

        thead {
            tr {
                transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
                transition-timing-function: cubic-bezier(.4,0,.2,1);
                transition-duration: .15s;

                th {
                    border-bottom-width: 1px;
                    font-weight: 600;
                    vertical-align: middle;
                    text-align: left;
                    padding-left: .5rem;
                    padding-right: .5rem;
                    height: 2.5rem;
                    border-color: #ebebeb;
                    color: var(--grey-700);
                }
            }
        }

        tbody {
            tr {
                transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
                transition-timing-function: cubic-bezier(.4,0,.2,1);
                transition-duration: .15s;

                &:nth-child(odd) {
                    background-color: #fafafa;

                    td {
                        background-color: #fafafa !important;
                    }
                }

                td {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                    vertical-align: middle;
                    padding-top: .625rem;
                    padding-bottom: .625rem;
                    padding-left: .5rem;
                    padding-left: 0rem;
                    padding-right: .5rem;
                    border: none !important;
                    color: var(----neutral-600);
                }
            }
        }
    }
    
    .full {
        width: 100%;
    }
}

 .table__striped__footer {
    border-top-width: 1px;
    vertical-align: middle;
    padding-top: .625rem;
    padding-bottom: .625rem;
    padding-left: .5rem;
    padding-right: .5rem;
    border-color: #ebebeb;
    border-style: solid;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__results {
        font-family: $font-stack-geist;
        color: #3c4257;
        font-weight: 500;
        font-size: 0.875rem;

        &.light {
            color: var(--grey-200) !important;
        }
    }
}