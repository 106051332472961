@import "~@/assets/scss/utils/mixins.scss";

.client__modal__body {
    padding: 0px 15px;
    margin-bottom: 10px;
    
    &.scroll-height {
        height: 290px;
        overflow: scroll;
    }
}

.follow__up {
    width: 100%;
    display: flex;
    align-items: baseline;

    &__preview {
        flex: 1;
        background: #f9fbfc;
        padding: 20px;

        &__text {
            margin-bottom: 1.3rem !important;
            font-size: 0.875rem;
            font-weight: 400;
            color: #09090B;
        }
    }
}
