@import "~@/assets/scss/utils/mixins.scss";

    textarea.form-control {
        border-radius: 4px !important;
    }

    .zm__count__badge {
        margin-right: 4px;
        border: 1px solid #e5e5e5;
        border-radius: 50%;
        font-size: 12px;
        color: #757676;
        background: #f3efff;

        .single {
            padding: 4px 6px;
        }

        .multiple {
            padding: 4px;
        }
    }
