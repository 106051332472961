<template>
    <div>
        <div class="pilled__tabs">
            <ul class="nav nav-pills">
                <li 
                    v-for="(tab, index) in tabs"
                    :key="tab.id"
                    class="pointer"
                    tabIndex="0"
                    role="tabItem"
                    @click="getCurrentTab(index)"
                >
                    <p class="nav-link" :class="{ 'active': selectedIndex === index ? true : false }" aria-current="page">
                        {{ tab.name }}
                    </p>
                </li>
            </ul>
        </div>
        <slot name="nonLinkedTab" v-bind="currentTab" />
    </div>
</template>

<script>
export default {
    name: "PilledTabs",

    props: {
        list: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            selectedIndex: 0, // the index of the selected tab,
            tabs: this.list, 
            currentTab: {
                index: 0,
                name: "",
                component: "",
                isActive: false,
            },
        };
    },

    mounted() {
        this.getCurrentTab(0);
    },

    methods: {
        getCurrentTab(i) {
            this.selectedIndex = i;
            
            this.tabs.forEach((tab, index) => {
                tab.isActive = (index === i);

                if (index === i) {
                    this.currentTab = {
                        index: index,
                        name: tab.name,
                        component: tab.component,
                        isActive: tab.isActive,
                    };
                }
            });
        },
    },
};
</script>

<style>
.pilled__tabs {
    position: relative;
    padding: 1rem;
    margin: 0 -1.5rem 1rem;

    @media (min-width: 768px) {
        padding: 1.5rem;
        margin-right: 0;
        margin-left: 0;
        border-width: 1px;
        border-radius: var(--bs-border-radius);
    }

    .nav-pills {
        --bs-nav-pills-border-radius: var(--bs-border-radius);
        --bs-nav-pills-link-active-color: #fff;
        --bs-nav-pills-link-active-bg: #0d6efd;
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }
}
</style>