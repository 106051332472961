@import "~@/assets/scss/utils/mixins.scss";

   .form__input {
        width: 100%;
        border-radius: 0px !important;
        border: none !important;
        background-color: var(--white, #FFF) !important;
        font-size: 0.875rem !important;
        border-bottom: 1px solid #eee !important;
    }

    .multiple__item {
        width: 100%;
        margin-bottom: 0.5rem;

        &.email {
            align-items: flex-end !important;
        }
    }

    .email {
        &--wrap {
            max-width: 100%;
            border-radius: 4px;
            border: 1px solid var(--gray-300, #E0E0E0);
            background-color: var(--white, #FFF);
            font-size: 0.875rem !important;
            padding: 8px 10px;
        }

        &--item {
            display: flex;
            align-items: center;
            background: #c7d2f5;
            border-radius: 50px;
            padding: 3px 8px;
            justify-content: center;
            font-size: 14px;
            margin-right: 5px;
            margin-bottom: 10px;
            width: fit-content;

            span {
                &:first-of-type {
                    margin-right: 5px;
                }
            }
        }
    }
    .multiple__emails {
        margin-left: 2rem;
        // max-width: 70%;
        // display: flex;
        // flex-wrap: wrap;
        // width: fit-content;
        // width: 70%;
        width: 100%;
    }

    .input-group-text {
        color: #596DDE;
        background: #f2ebff;
        padding-bottom: 5px;
        padding-top: 5px;
        font-size: 14px;
    }
