.client {
    &__content {
        display: flex;
        margin-bottom: 2rem;
        align-items: baseline;

        &__left {
            flex: 1;
            flex-basis: 40%;
            margin-right: 1.5rem;
            border: 1px solid #ddd;
            border-radius: 8px;

            &__header {
                padding: 15px 20px 20px;
                background: #f7f7f7 !important;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            &__body {
                padding: 10px 20px;
            }

            .sub__tag {
                display: inline !important;
                vertical-align: middle !important;
                align-items: center;
                margin-left: 0px;
                border: 1px solid #cecece !important;
                border-radius: 20px;
                background: white !important;
                padding: 4px 10px;
                font-size: 0.875rem;

                &:not(:last-of-type) {
                    margin-right: 5px;
                }
            }
        }

        &__right {
            flex: 2;
            flex-basis: 60%;
        }
    }

    &__group {
        padding: 5px 0;

        &:not(:last-of-type) {
            border-bottom:1px solid #cecece !important;
            margin-bottom: 0.75rem;
        }

        p.title {
            color: #202020;
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 0.75rem !important;
            text-transform: capitalize;
        }

        .email__wrap {
            display: flex;
            flex-wrap: wrap;
            max-width: 80%;

            >span {
                margin-right: 0px !important;

                &:not(:last-of-type) {
                    margin-bottom: 10px;
                }
            }
        }
    }

    &__item {
        width: 100%;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.email {
            align-items: flex-end !important;
        }

        &__title {
            color: #667085;
            font-size: 0.875rem;
            font-weight: 500;
        }

        &__desc {
            color: #202020;
            font-size: 0.875rem;
            margin-top: 4px;
        }

        &__left, &__right {
            max-width: 50%;
            width: 50%;
        }

        &__action__btns {
            display: flex;
            align-items: center;
            margin-top: 0.875rem;
        }

        &__meta {
            margin-top: 2rem;
        }

        span.title, span.sub {
            color: #202020;
            font-size: 0.875rem;
            font-weight: normal;
        }

        span.sub {
            font-weight: 500 !important;
        }

        &__details {
            &__sub {
                max-height: 500px;
            }
        }
    }
    
    &__header {
        &__status {
            display: flex;
            align-items: center;
            justify-content: end;
            margin-bottom: 10px;
            width: 100%;
        }
    }
    
    &__notes {
        &__item {
            padding-bottom: 0.75rem;
            padding-top: 0.75rem;
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 1rem;

            &:not(:last-of-type) {
                border-bottom: 1px solid #E6E6E6;
            }
        }

        &__form {
            height: auto;
        }

        &__action__btns {
            display: flex;
            margin: 10px 1rem 10px 0;
            justify-content: flex-end;
            width: 100%;
            padding-right: 1rem;
        }
    }
}