@import "~@/assets/scss/utils/mixins.scss";

.client__modal__body {    
    &.scroll-height {
        max-height: 490px;
        overflow: scroll;

        .form--control {
            &:not(:last-of-type) {
                margin-bottom: 15px !important;
            }
        }
    }
}
