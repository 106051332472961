@import "~@/assets/scss/utils/mixins.scss";

    .multiple__item {
        width: 100%;
        margin-bottom: 1rem;

        &.email {
            align-items: flex-end !important;
        }
    }
    .email {
        &--wrap {
            max-width: 100%;
            border-radius: 4px;
            border: 1px solid var(--gray-300, #E0E0E0);
            background-color: var(--white, #FFF);
            padding: 8px 10px;
        }

        &--item {
            display: flex;
            align-items: center;
            background: #c7d2f5;
            border-radius: 50px;
            padding: 3px 8px;
            justify-content: center;
            font-size: 12px;
            margin-right: 5px;
            margin-bottom: 10px;
            width: fit-content;

            span {
                &:first-of-type {
                    margin-right: 5px;
                }
            }
        }
    }

    .multiple__emails {
        margin-left: 0rem;
        width: 100%;
    }
